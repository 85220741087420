import Label from "../Label";
import ModelViewer from "../ModelViewer/ModelViewer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruck } from "@fortawesome/pro-regular-svg-icons";
import React, { useState, Fragment, useEffect, useRef, useCallback } from "react";
import Popup from "reactjs-popup";
import ColorUtil from "../ModelViewer/ColorUtil";
import { handleLocalStorageData } from "../Functions/functions";
import OrderOverviewModal from "../Popups/OrderPopup";
import ShippingOverviewModal from "../Popups/ShippingPopup";
import axios from "axios";
import { useGlobalState } from "../GlobalContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { calculateTransitTime } from "../ProductSettings/CalculateTransitTime";
import { getTotalPrice } from "../Functions/price-calculations";
import TableQuantityInput from "./TableQuantityInput";
import FileConversionProgress from "./FileConversionProgress";

const TableRow = function TableRow(props) {
    const [modalOpen, setModalOpen] = useState([]);
    const [showShippingModal, setShowShippingModal] = useState([]);
    const [tableData, setTableData] = useState(props.data);
    const updatingModel = useRef(null);
    const amountTimeout = useRef(null);
    const getColorInRgb = ColorUtil(props);
    const { data, rowCheck, onModelChange, interaction, responsive, checkoutState } = props;
    const { productSettings, mandatoryProductSettings, loggedInUser, currentPage } = useGlobalState();
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [calculatingProduct, setCalculatingProduct] = useState(null);
    const [uploadProgressions, setUploadProgressions] = useState([]);
    const memoizedSetUploadProgressions = useCallback(setUploadProgressions, []);

    useEffect(() => {
        setTableData(props.data);
    }, [props.data]);

    useEffect(() => {
        if (amountTimeout.current !== null) {
            props.setTotalPriceLoading(true);
        } else {
            props.setTotalPriceLoading(false);
        }
    }, [amountTimeout.current]);

    useEffect(() => {
        if (typeof props.openPopup !== "undefined" && props.openPopup !== null) {
            handleOrderOverview("view", props.openPopup.id, props.openPopup.type);
        }
    }, [props.openPopup]);

    const allModelsChecked = () => {
        // Get all checkboxes
        const checkboxes = document.getElementsByClassName("modelViewerContainer");
        // Get input child from each checkbox
        const inputs = [];
        for (let i = 0; i < checkboxes.length; i++) {
            inputs.push(checkboxes[i].getElementsByTagName("input")[0]);
        }
        // Check if all inputs are checked
        for (let i = 0; i < inputs.length; i++) {
            if (inputs[i].checked === false) {
                return false;
            }
        }
        return true;
    };

    // Checkbox logic if state in Location Object is filled.
    useEffect(() => {
        if (!!data && checkoutState) {
            const modelId = checkoutState.model_id;
            data.forEach((model) => {
                if (model.id === modelId) {
                    let dataType =
                        window.location.pathname === props.getPageUrlByName("account_edit_quotation").path
                            ? "modelsDataQuotation"
                            : "modelsData";
                    onModelChange((prevState) => {
                        const localStorageData = JSON.parse(window.localStorage.getItem(dataType));
                        localStorageData[0].data.forEach((m) => {
                            m.checked = false;
                            if (m.id === model.id) {
                                m.checked = true;
                            }
                        });
                        handleLocalStorageData([...localStorageData], dataType);
                        return [...localStorageData];
                    });
                }
            });
            window.history.replaceState({}, document.title);
        }
    }, [!!data, checkoutState]);

    const handleQuotationModel = (type, model, index, amount, forceChecked) => {
        const updateState = (updater) => {
            onModelChange((prevState) => {
                let dataType =
                    window.location.pathname === props.getPageUrlByName("account_edit_quotation").path
                        ? "modelsDataQuotation"
                        : "modelsData";
                const newState = updater([...prevState]);
                handleLocalStorageData(newState, dataType);
                return newState;
            });
        };
        switch (type) {
            case "copy":
                axios.post("/api/duplicate-model/", { model_id: model.id }).then((response) => {
                    const newModel = response.data.model;
                    updateState((prevState) => {
                        return [
                            {
                                ...prevState[0],
                                data: [...prevState[0].data, { ...model, id: newModel.id }],
                            },
                            ...prevState.slice(1),
                        ];
                    });
                    // Scroll the user down to show the newly copied model
                    setTimeout(() => {
                        let container = document.getElementsByClassName("table-container");
                        if (container !== null && typeof container !== "undefined" && container.length > 0) {
                            document
                                .getElementsByClassName("table-container")[0]
                                .scrollTo({ top: 100000, behavior: "smooth" });
                        }
                    }, 100);
                });
                break;
            case "delete":
                updateState((prevState) => [
                    { ...prevState[0], data: prevState[0].data.filter((m, i) => i !== index) },
                    ...prevState.slice(1),
                ]);

                // Delete model from database (except when editing a quotation)
                if (currentPage !== "account_edit_quotation") {
                    axios.delete("/models/" + model.id);
                }
                break;
            case "quantity":
                if (amountTimeout !== null) {
                    clearTimeout(amountTimeout);
                }

                // Get all checked models
                const checkedModels = [];
                data.forEach((model) => {
                    if (model.checked === true) {
                        checkedModels.push(model);
                    }
                });

                // Check if all models are selected
                if (checkedModels.length > 1) {
                    updateState((prevState) => {
                        // Update all selected models
                        prevState[0].data.forEach((m) => {
                            checkedModels.forEach((checkedModel) => {
                                if (checkedModel.id === m.id) {
                                    m.amount = parseInt(amount);
                                }
                            });
                        });
                        return [...prevState];
                    });

                    let timeout = setTimeout(() => {
                        checkedModels.forEach((mdl) => {
                            props.updateShippingCosts(mdl, true);
                            props.updatePrice(mdl);
                        });
                        updatingModel.current = null;
                        amountTimeout.current = null;
                    }, 500);
                    updatingModel.current = model;
                    amountTimeout.current = timeout;
                } else {
                    updateState((prevState) => {
                        prevState[0].data[index].amount = parseInt(amount);
                        return [...prevState];
                    });
                    let timeout = setTimeout(() => {
                        // Update delivery time / price
                        props.updateShippingCosts(model, true);
                        props.updatePrice(model);
                        updatingModel.current = null;
                        amountTimeout.current = null;
                    }, 500);
                    updatingModel.current = model;
                    amountTimeout.current = timeout;
                }
                break;
            case "checkbox":
                if (model.checked) {
                    document.getElementsByClassName("checkbox--select-all")[0].checked = false;
                }

                forceChecked
                    ? (model.checked = true)
                    : (model.checked = model.checked === null ? true : !model.checked);
                updateState((prevState) => {
                    prevState[0].data.forEach((m) => {
                        if (model.id === m.id && model.path === m.path) {
                            m.checked = model.checked;
                        }
                    });
                    return [...prevState];
                });

                if (allModelsChecked()) {
                    document.getElementsByClassName("checkbox--select-all")[0].click();
                } else {
                    document.getElementsByClassName("delete-all-header-btn")[0].style.opacity = "0";
                }
                break;
            default:
                break;
        }
    };

    const addLibraryProductToCart = (product) => {
        const products = JSON.parse(window.localStorage.getItem("productsData"));

        // Check if product already exists in cart
        let found = false;
        if (products !== null) {
            products.filter((p) => {
                if (p.id === product.id) {
                    found = true;
                }
            });
        }

        if (found) {
            toast.error(props.allTextData["account/product_library"]["product_already_in_cart"]);
            return;
        }

        if (products !== null) {
            products.push(product);
            window.localStorage.setItem("productsData", JSON.stringify(products));
            props.setProductsData(products);
        } else {
            window.localStorage.setItem("productsData", JSON.stringify([product]));
            props.setProductsData([product]);
        }

        if (typeof product.dimensions === "undefined" || product.dimensions === null) {
            setCalculatingProduct(product);
        }

        toast.success(props.allTextData["account/product_library"]["product_added_to_cart"]);
    };

    const anyModelStillLoading = (data) => {
        let selectAll = document.getElementsByClassName("checkbox--select-all");
        let tinySpinners = document.getElementsByClassName("tiny-spinner");
        if (tinySpinners.length > 0) {
            if (selectAll.length > 0) {
                selectAll[0].disabled = true;
            }
            return true;
        }
        if (selectAll.length > 0) {
            selectAll[0].disabled = false;
        }
        return false;
    };

    const returnProductDimensions = (size, productId) => {
        axios.post(window.location.origin + "/update-product-dimensions", {
            width: size.width,
            height: size.height,
            length: size.length,
            volume: size.volume,
            surface: size.surface,
            product_id: productId,
        });

        setCalculatingProduct(null);
    };

    const deleteLibraryProduct = (product) => {
        let productsData = JSON.parse(window.localStorage.getItem("productsData"));
        productsData = productsData.filter((p) => p.id !== product.id);
        window.localStorage.setItem("productsData", JSON.stringify(productsData));
        props.setProductsData(productsData);
    };

    const handleLibraryProductDownload = (product) => {
        axios
            .get("/model?type=library_product&manualDownload=1&id=" + product.id, { responseType: "blob" })
            .then((response) => {
                // Donwload model
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", product.file);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch((error) => {
                toast.error(props.allTextData["account/product_library"]["cant_find_model"]);
            });
    };

    const getExtension = (file) => {
        // get extension from filename
        let extension = file.split(".").pop();
        return extension;
    };

    const updateLibraryProductQuantity = (product, index, amount) => {
        // update tableData
        const newTableData = [...tableData];
        if (typeof newTableData[index] !== "undefined") {
            newTableData[index].quantity = parseInt(amount);
            setTableData(newTableData);
        } else {
            if (typeof props.productsData !== "undefined") {
                let newProductsData = props.productsData.filter((p, i) => {
                    if (p.id === product.id) {
                        p.quantity = parseInt(amount);
                    }
                    return p;
                });
                localStorage.setItem("productsData", JSON.stringify(newProductsData));
                props.setProductsData(newProductsData);
            }
        }
    };

    const deleteQuotation = (id) => {
        axios.delete("/quotation/" + id).then((response) => {
            setTableData((prevState) => {
                return prevState.filter((row) => row.project_number !== id);
            });
        });
    };

    const getTemporaryTableRow = (index, model) => {
        if (!props.responsive) {
            return (
                <tr key={index} data-model-id={model.id}>
                    <td colSpan="100%" style={{ width: "100%" }}>
                        <div className="modelViewerContainer">
                            <input
                                type="checkbox"
                                checked={false}
                                disabled={true}
                                onChange={(e) => handleQuotationModel(Object.values(interaction.checkbox)[0], model)}
                            />
                            <FileConversionProgress
                                uploadProgressions={uploadProgressions}
                                setUploadProgressions={memoizedSetUploadProgressions}
                                model={model}
                            />
                        </div>
                    </td>
                </tr>
            );
        } else {
            return (
                <tr key={index} data-model-id={model.id} style={{ height: "100px" }}>
                    <td colSpan="100%">
                        <div className="modelViewerContainer">
                            <input
                                type="checkbox"
                                checked={false}
                                disabled={true}
                                onChange={(e) => handleQuotationModel(Object.values(interaction.checkbox)[0], model)}
                            />
                            <FileConversionProgress
                                uploadProgressions={uploadProgressions}
                                setUploadProgressions={setUploadProgressions}
                                model={model}
                            />
                        </div>
                    </td>
                    <td colSpan="100%">
                        <p>...</p>
                    </td>
                </tr>
            );
        }
    };

    const handleOrderOverview = (type, id) => {
        switch (type) {
            case "shipping-info":
                setShowShippingModal((prevState) => {
                    const newState = [];
                    newState[id] = true;
                    return newState;
                });
                setModalOpen((prevState) => {
                    const newState = [];
                    return newState;
                });
                break;
            case "download":
                const downloadLink = document.createElement("a");
                downloadLink.href = window.location.origin + `/quotation/generate-pdf/` + id;
                var today = new Date().toLocaleDateString("nl-NL");
                today = today.replace(/-/g, "");
                downloadLink.setAttribute("download", `YO${id}${today}.pdf`);
                document.body.appendChild(downloadLink);
                downloadLink.click();
                downloadLink.remove();
                break;
            case "view":
                setModalOpen((prevState) => {
                    const newState = [];
                    newState[id] = true;
                    return newState;
                });
                setShowShippingModal((prevState) => {
                    const newState = [];
                    return newState;
                });
                break;
            case "delete":
                deleteQuotation(id);
                toast.success("Offerte verwijderd!", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                break;
            default:
                break;
        }
    };

    const getModelPrice = (model) => {
        if (typeof model.price === "string" && /[a-z]/i.test(model.price)) {
            return model.price;
        }

        let regularPrice = model.amount * model.price;
        let discountPrice = regularPrice;
        if (loggedInUser.company !== null && loggedInUser.company.allow_staffel_discount == true) {
            discountPrice = regularPrice * model.discount_multiplier;
        }

        // if (loggedInUser.company !== null && loggedInUser.company.discount_percentage > 0) {
        //     if (loggedInUser.company.fixed_discount === false) {
        //         let companyDisc = (regularPrice / 100) * loggedInUser.company.discount_percentage;
        //         discountPrice -= companyDisc;

        //         return (
        //             <>
        //                 {((typeof model.loadingPrice !== "undefined" && model.loadingPrice === true) ||
        //                     (amountTimeout !== null && updatingModel.current?.id === model.id)) && (
        //                     <p className="price"> ... </p>
        //                 )}
        //                 {model.loadingPrice !== true && updatingModel.current?.id !== model.id && (
        //                     <>
        //                         <s>
        //                             {!isNaN(regularPrice) && <>&euro;</>}
        //                             {!isNaN(regularPrice) && regularPrice.toFixed(2)}
        //                             {!isNaN(regularPrice) &&
        //                                 typeof model.price === "string" &&
        //                                 /[a-z]/i.test(regularPrice) &&
        //                                 regularPrice}
        //                         </s>
        //                         <br />
        //                         <p className="price">
        //                             {!isNaN(discountPrice) && <>&euro;</>}
        //                             {!isNaN(discountPrice) && discountPrice.toFixed(2)}
        //                             {!isNaN(discountPrice) &&
        //                                 typeof model.price === "string" &&
        //                                 /[a-z]/i.test(discountPrice) &&
        //                                 discountPrice}
        //                             {typeof model.price === "string" && /[a-z]/i.test(model.price) && model.price}
        //                         </p>
        //                     </>
        //                 )}
        //             </>
        //         );
        //     } else {
        //         return (
        //             <>
        //                 {((typeof model.loadingPrice !== "undefined" && model.loadingPrice === true) ||
        //                     (amountTimeout !== null && updatingModel.current?.id === model.id)) && (
        //                     <p className="price"> ... </p>
        //                 )}
        //                 {model.loadingPrice !== true && updatingModel.current?.id !== model.id && (
        //                     <>
        //                         {regularPrice !== discountPrice && (
        //                             <>
        //                                 <s>
        //                                     {!isNaN(regularPrice) && <>&euro;</>}
        //                                     {!isNaN(regularPrice) && regularPrice.toFixed(2)}
        //                                 </s>
        //                                 <br />
        //                             </>
        //                         )}
        //                         {!isNaN(discountPrice) && <>&euro;</>}
        //                         {!isNaN(discountPrice) && discountPrice.toFixed(2)}
        //                         {!isNaN(regularPrice) &&
        //                             typeof model.price === "string" &&
        //                             /[a-z]/i.test(regularPrice) &&
        //                             regularPrice}
        //                     </>
        //                 )}
        //             </>
        //         );
        //     }
        // }

        return (
            <>
                {((typeof model.loadingPrice !== "undefined" && model.loadingPrice === true) ||
                    (amountTimeout !== null && updatingModel.current?.id === model.id)) && (
                    <p className="price"> ... </p>
                )}
                {model.loadingPrice !== true && updatingModel.current?.id !== model.id && (
                    <>
                        {model.discount_multiplier !== 1 &&
                            !/[a-z]/i.test(model.price) &&
                            parseFloat(model.price * model.amount) !== parseFloat(discountPrice) && (
                                <s>
                                    {!isNaN(model.price) && <>€</>}
                                    {!isNaN(model.price) && parseFloat(regularPrice).toFixed(2)}
                                    {typeof model.price === "string" &&
                                        !/[a-z]/i.test(model.price) &&
                                        parseFloat(regularPrice).toFixed(2)}
                                </s>
                            )}
                        <p className="price">
                            {!isNaN(model.price) && <>&euro;</>}
                            {!isNaN(discountPrice) &&
                                typeof loggedInUser.company !== "undefined" &&
                                (loggedInUser.company === null ||
                                    loggedInUser.company.allow_staffel_discount != false) &&
                                parseFloat(discountPrice).toFixed(2)}
                            {!isNaN(regularPrice) &&
                                typeof loggedInUser.company !== "undefined" &&
                                loggedInUser.company !== null &&
                                loggedInUser.company.allow_staffel_discount == false &&
                                regularPrice.toFixed(2)}
                            {typeof model.price === "string" && /[a-z]/i.test(model.price) && model.price}
                        </p>
                    </>
                )}
            </>
        );
    };

    const ModelTableData = () => {
        const [showModal, setShowModal] = useState(false);
        const [modelPath, setModelPath] = useState(null);
        const [modelColor, setModelColor] = useState(null);
        const [cachedModel, setCachedModel] = useState(false);
        const [modelExtension, setModelExtension] = useState(null);
        const [orientationLocked, setOrientationLocked] = useState(null);
        const [modelId, setModelId] = useState(null);
        const handleModelViewer = (type, model) => {
            let orientation = model?.settings?.orientation === "unlocked" ? false : true;
            let color;
            const setVars = (type) => {
                switch (type) {
                    case "open":
                        setShowModal(true);
                        const path = model.glb_path ? model.glb_path : model.path;
                        setModelPath(path.replace("public", "storage"));
                        setModelColor(color);
                        setCachedModel(model.cached_model);
                        setModelExtension(/[^.]+$/.exec(model.file)[0].trim());
                        setOrientationLocked(orientation);
                        setModelId(model.id);
                        break;
                    case "close":
                        setShowModal(false);
                        setModelPath(null);
                        setModelColor(null);
                        setCachedModel(null);
                        setModelExtension(null);
                        setOrientationLocked(null);
                        setModelId(null);
                        break;
                    default:
                        break;
                }
            };
            if (typeof model?.color !== "undefined") {
                color = model.color;
                setVars(type);
            } else {
                getColorInRgb(model?.setting?.color).then((res) => {
                    color = res;
                    setVars(type);
                });
            }
        };
        return (
            <>
                {showModal && !!modelPath && (
                    <Popup
                        open={showModal}
                        onClose={() => handleModelViewer("close")}
                        modal
                        nested
                        closeOnDocumentClick
                        className="quotation-3d-container"
                    >
                        {(close) => (
                            <div className="modal">
                                <button className="close" onClick={close}>
                                    &times;
                                </button>
                                <div id={"modelviewer-container-full"}>
                                    <div className="modelviewer-stats"></div>
                                    {!!cachedModel && cachedModel === true && (
                                        <ModelViewer
                                            modelData={cachedModel}
                                            extension={modelExtension}
                                            container={"modelviewer-container-full"}
                                            width={500}
                                            height={500}
                                            color={modelColor ? modelColor : null}
                                            orientationLocked={orientationLocked}
                                            cachedData={true}
                                            modelId={modelId}
                                            showXYZ={true}
                                        />
                                    )}
                                    {cachedModel === null ||
                                        (cachedModel === false && (
                                            <ModelViewer
                                                model={modelPath}
                                                container={"modelviewer-container-full"}
                                                width={500}
                                                height={500}
                                                color={modelColor ? modelColor : null}
                                                orientationLocked={orientationLocked}
                                                showXYZ={true}
                                            />
                                        ))}
                                </div>
                            </div>
                        )}
                    </Popup>
                )}
                {!responsive &&
                    !!data &&
                    data.length > 0 &&
                    data.map((model, index) => (
                        <React.Fragment key={index}>
                            {typeof model.tempId !== "undefined" && getTemporaryTableRow(index, model)}
                            {typeof model.tempId === "undefined" && typeof model.id !== "undefined" && (
                                <tr data-model-id={model.id}>
                                    <td>
                                        <div className={"modelViewerContainer"} id={"modelViewerContainer" + index}>
                                            <input
                                                type="checkbox"
                                                checked={model.checked}
                                                disabled={anyModelStillLoading(data)}
                                                onChange={(e) =>
                                                    handleQuotationModel(Object.values(interaction.checkbox)[0], model)
                                                }
                                            />
                                            {typeof model.loading !== "undefined" && model.loading === true && (
                                                <span className="tiny-spinner"></span>
                                            )}
                                            {model.loading !== true && (
                                                <img
                                                    onClick={() => handleModelViewer("open", model)}
                                                    src={model.image}
                                                    alt=""
                                                />
                                            )}
                                        </div>
                                    </td>
                                    <td>
                                        <p>{model.file}</p>
                                    </td>
                                    <td className="label-quotation">
                                        {typeof props.textDataLabel !== "undefined" && (
                                            <Label
                                                modelId={model.id}
                                                data={model.settings}
                                                textDataLabel={props.textDataLabel}
                                                mandatoryProductOptions={props.mandatoryProductOptions}
                                                showall={!responsive}
                                            />
                                        )}
                                        {typeof props.textDataLabel === "undefined" && (
                                            <Label
                                                modelId={model.id}
                                                data={model.settings}
                                                mandatoryProductOptions={props.mandatoryProductOptions}
                                                showall={!responsive}
                                            />
                                        )}
                                    </td>
                                    <td>
                                        {amountTimeout !== null && updatingModel.current?.id === model.id && (
                                            <p className="delivery"> ... </p>
                                        )}
                                        {((!!props.allTextData && amountTimeout === null) ||
                                            (amountTimeout !== null && updatingModel.current?.id !== model.id)) && (
                                            <>
                                                {model.delivery !== "..." &&
                                                    calculateTransitTime(productSettings, model) > 1 &&
                                                    "+/- " +
                                                        calculateTransitTime(productSettings, model) +
                                                        " " +
                                                        props.allTextData["account/quotations"]["workdays"]}
                                                {model.delivery !== "..." &&
                                                    calculateTransitTime(productSettings, model) === 1 &&
                                                    "+/- " +
                                                        calculateTransitTime(productSettings, model) +
                                                        " " +
                                                        props.allTextData["account/quotations"]["workday"]}
                                                {(model.delivery === "..." ||
                                                    typeof model.delivery === "undefined") && (
                                                    <p className="delivery"> ... </p>
                                                )}
                                            </>
                                        )}
                                    </td>
                                    <td>
                                        <TableQuantityInput
                                            index={index}
                                            model={model}
                                            handleQuotationModel={handleQuotationModel}
                                            interaction={interaction}
                                        />
                                    </td>
                                    <td>
                                        {model.discount_multiplier !== 1 && !/[a-z]/i.test(model.price) && (
                                            <>
                                                {loggedInUser.company === null ||
                                                    (loggedInUser.company.allow_staffel_discount == true && (
                                                        <>
                                                            <s>&euro;{model.price.toFixed(2)}</s>
                                                            <br />
                                                            <p className="price">
                                                                &euro;
                                                                {(model.price * model.discount_multiplier).toFixed(2)}
                                                            </p>
                                                        </>
                                                    ))}
                                                {loggedInUser.company !== null &&
                                                    loggedInUser.company.allow_staffel_discount == false && (
                                                        <>
                                                            <p className="price">&euro;{model.price.toFixed(2)}</p>
                                                        </>
                                                    )}
                                            </>
                                        )}
                                        {model.discount_multiplier === 1 && (
                                            <>{/[a-z]/i.test(model.price) ? "-" : "€" + model.price.toFixed(2)}</>
                                        )}
                                        {model.discount_multiplier !== 1 && /[a-z]/i.test(model.price) && <>{"-"}</>}
                                    </td>
                                    <td>{getModelPrice(model)}</td>
                                    <td colSpan="100%">
                                        {!!interaction && interaction.copyBtn && interaction.deleteBtn && (
                                            <>
                                                <button
                                                    className={"button " + interaction.copyBtn.iconClass}
                                                    onClick={(e) =>
                                                        handleQuotationModel(
                                                            Object.values(interaction.copyBtn)[1],
                                                            model
                                                        )
                                                    }
                                                ></button>
                                                <button
                                                    className={"button " + interaction.deleteBtn.iconClass}
                                                    onClick={() =>
                                                        handleQuotationModel(
                                                            Object.values(interaction.deleteBtn)[1],
                                                            model,
                                                            index
                                                        )
                                                    }
                                                ></button>
                                            </>
                                        )}
                                    </td>
                                </tr>
                            )}
                        </React.Fragment>
                    ))}
                {!!responsive &&
                    !!data &&
                    data.length > 0 &&
                    data.map((model, index) => (
                        <Fragment key={index}>
                            {typeof model.tempId !== "undefined" && getTemporaryTableRow(index, model)}
                            {typeof model.tempId === "undefined" && typeof model.id !== "undefined" && (
                                <Fragment>
                                    <tr style={model.loading === true ? { height: "100px" } : null}>
                                        <td colSpan="100%">
                                            <div className={"modelViewerContainer"} id={"modelViewerContainer" + index}>
                                                <input
                                                    type="checkbox"
                                                    checked={model.checked}
                                                    onChange={(e) =>
                                                        handleQuotationModel(
                                                            Object.values(interaction.checkbox)[0],
                                                            model
                                                        )
                                                    }
                                                />
                                                {typeof model.loading !== "undefined" && model.loading === true && (
                                                    <span className="tiny-spinner"></span>
                                                )}
                                                {model.loading !== true && (
                                                    <img
                                                        onClick={() => handleModelViewer("open", model)}
                                                        src={model.image}
                                                        alt=""
                                                    />
                                                )}
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <span className="model-filename">{model.file}</span>
                                                <div className="label-quotation">
                                                    {typeof props.textDataLabel !== "undefined" && (
                                                        <Label
                                                            responsive={responsive}
                                                            modelId={model.id}
                                                            data={model.settings}
                                                            textDataLabel={props.textDataLabel}
                                                            mandatoryProductOptions={props.mandatoryProductOptions}
                                                        />
                                                    )}
                                                    {typeof props.textDataLabel === "undefined" && (
                                                        <Label
                                                            responsive={responsive}
                                                            modelId={model.id}
                                                            data={model.settings}
                                                            mandatoryProductOptions={props.mandatoryProductOptions}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="horizontal-line-row">
                                        <td colSpan="100%">
                                            <hr />
                                        </td>
                                        <td>
                                            <hr />
                                        </td>
                                    </tr>
                                    <tr className="card-body-actions">
                                        <td colSpan="100%">
                                            {window.innerWidth <= 499 && (
                                                <div className="quotation-actions">
                                                    <div className="delivery-text">
                                                        <FontAwesomeIcon icon={faTruck} size="xl" />
                                                        {model.delivery}
                                                    </div>
                                                    <div className="quantity-block">
                                                        {!!interaction && !!interaction.quantityInput && (
                                                            <input
                                                                onChange={(e) =>
                                                                    handleQuotationModel(
                                                                        Object.values(interaction.quantityInput)[0],
                                                                        model,
                                                                        index,
                                                                        e.target.value
                                                                    )
                                                                }
                                                                type="number"
                                                                value={model.amount}
                                                                min={1}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </td>
                                        <td>
                                            <div className="quotation-actions">
                                                {window.innerWidth >= 500 && (
                                                    <Fragment>
                                                        <div className="delivery-text">
                                                            <FontAwesomeIcon icon={faTruck} size="xl" />
                                                            {model.delivery}
                                                        </div>
                                                        <div className="quantity-block">
                                                            {!!interaction && !!interaction.quantityInput && (
                                                                <input
                                                                    onChange={(e) =>
                                                                        handleQuotationModel(
                                                                            Object.values(interaction.quantityInput)[0],
                                                                            model,
                                                                            index,
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                    type="number"
                                                                    value={model.amount}
                                                                    min={1}
                                                                />
                                                            )}
                                                        </div>
                                                    </Fragment>
                                                )}
                                                <div className="price-block">{getModelPrice(model)}</div>
                                                <div className="button-block">
                                                    {!!interaction && interaction.copyBtn && interaction.deleteBtn && (
                                                        <>
                                                            <button
                                                                className={"button " + interaction.copyBtn.iconClass}
                                                                onClick={(e) =>
                                                                    handleQuotationModel(
                                                                        Object.values(interaction.copyBtn)[1],
                                                                        model
                                                                    )
                                                                }
                                                            ></button>
                                                            <button
                                                                className={"button " + interaction.deleteBtn.iconClass}
                                                                onClick={() =>
                                                                    handleQuotationModel(
                                                                        Object.values(interaction.deleteBtn)[1],
                                                                        model,
                                                                        index
                                                                    )
                                                                }
                                                            ></button>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </Fragment>
                            )}
                        </Fragment>
                    ))}
                {props.productsData &&
                    props.productsData.length > 0 &&
                    props.productsData.map((product, index) => (
                        <React.Fragment key={index}>
                            <tr data-model-id={product.id}>
                                <td>
                                    <div className={"modelViewerContainer"} id={"modelViewerContainer" + index}>
                                        {/* <input
                                                type="checkbox"
                                                checked={product.checked}
                                                onChange={(e) =>
                                                    console.log('todo')
                                                }
                                            /> */}
                                        <img width={100} height={100} src={product.preview_image} alt="" />
                                    </div>
                                </td>
                                <td>
                                    <p>{product.name}</p>
                                </td>
                                <td className="label-quotation">
                                    {typeof props.textDataLabel !== "undefined" && (
                                        <Label
                                            modelId={product.id}
                                            data={product.settings}
                                            textDataLabel={props.textDataLabel}
                                            mandatoryProductOptions={props.mandatoryProductOptions}
                                            showall={!responsive}
                                        />
                                    )}
                                    {typeof props.textDataLabel === "undefined" && (
                                        <Label
                                            modelId={product.id}
                                            data={product.settings}
                                            mandatoryProductOptions={props.mandatoryProductOptions}
                                            showall={!responsive}
                                        />
                                    )}
                                </td>
                                <td>
                                    {amountTimeout !== null && updatingModel.current?.id === product.id && (
                                        <p className="delivery"> ... </p>
                                    )}
                                    {((!!props.allTextData && amountTimeout === null) ||
                                        (amountTimeout !== null && updatingModel.current?.id !== product.id)) && (
                                        <>
                                            {product.delivery !== "..." &&
                                                calculateTransitTime(productSettings, product) > 1 &&
                                                "+/- " +
                                                    calculateTransitTime(productSettings, product) +
                                                    " " +
                                                    props.allTextData["account/quotations"]["workdays"]}
                                            {product.delivery !== "..." &&
                                                calculateTransitTime(productSettings, product) === 1 &&
                                                "+/- " +
                                                    calculateTransitTime(productSettings, product) +
                                                    " " +
                                                    props.allTextData["account/quotations"]["workday"]}
                                            {(product.delivery === "..." ||
                                                typeof product.delivery === "undefined") && (
                                                <p className="delivery"> ... </p>
                                            )}
                                        </>
                                    )}
                                </td>
                                <td>
                                    {!!interaction && !!interaction.quantityInput && (
                                        <input
                                            id={"amount-" + index}
                                            onChange={(e) => {
                                                if (e.target.value < 9999 && e.target.value > -1) {
                                                    updateLibraryProductQuantity(product, index, e.target.value);
                                                }
                                            }}
                                            type="number"
                                            value={product.quantity}
                                            min={1}
                                            max={9999}
                                        />
                                    )}
                                </td>
                                <td>&euro;{parseFloat(product.price).toFixed(2)}</td>
                                <td>&euro;{(parseFloat(product.price) * parseInt(product.quantity)).toFixed(2)}</td>
                                <td colSpan="100%">
                                    {!!interaction && interaction.copyBtn && interaction.deleteBtn && (
                                        <>
                                            <button
                                                className={"button " + interaction.deleteBtn.iconClass}
                                                onClick={() => deleteLibraryProduct(product)}
                                            ></button>
                                        </>
                                    )}
                                </td>
                            </tr>
                        </React.Fragment>
                    ))}
            </>
        );
    };
    const OrdersTableData = () => {
        return (
            <>
                {!responsive &&
                    !!data &&
                    data.length > 0 &&
                    data.map((order, index) => (
                        <Fragment key={index}>
                            <tr
                                className="order-table-row"
                                onClick={(e) => {
                                    // Only open popup if popup is not opened yet
                                    if (
                                        e.target.className.length === 0 ||
                                        e.target.className === "model-preview-image"
                                    ) {
                                        handleOrderOverview(
                                            Object.values(interaction.viewBtn)[1],
                                            order.project_number
                                        );
                                    }
                                }}
                            >
                                <td>
                                    <div className="model-preview-image-container">
                                        <img
                                            width={100}
                                            className="model-preview-image"
                                            src={order.modelImage}
                                            alt="3d-model-preview"
                                        />
                                        {order.models.length > 1 && (
                                            <div className="second">
                                                <img
                                                    width={100}
                                                    className="model-preview-image"
                                                    src={order.models[1].preview_image}
                                                    alt="3d-model-preview"
                                                />
                                                <p>+{order.models.length - 1}</p>
                                            </div>
                                        )}
                                    </div>
                                </td>
                                <td>{order.models.length + order.products.length}</td>
                                <td>{order.project_number}</td>
                                <td>{order.orderDate}</td>
                                <td>
                                    <p className="reference">{order.reference}</p>
                                </td>
                                <td>
                                    <Label data={order.status} />
                                </td>
                                <td>
                                    <>&euro;</>
                                    {getTotalPrice(
                                        order,
                                        loggedInUser,
                                        setTotalDiscount,
                                        mandatoryProductSettings,
                                        productSettings
                                    )}
                                </td>
                                <td>
                                    {!!interaction && (
                                        <>
                                            {!!order.status &&
                                                order.status !== "production" &&
                                                order.trackingInfo.length > 0 && (
                                                    <button
                                                        className={"button " + interaction.shippingInfoBtn.iconClass}
                                                        onClick={(e) =>
                                                            handleOrderOverview(
                                                                Object.values(interaction.shippingInfoBtn)[1],
                                                                order.project_number
                                                            )
                                                        }
                                                    ></button>
                                                )}
                                            {!!order.status && order.status !== "rejected" && (
                                                <button
                                                    className={"button " + interaction.downloadBtn.iconClass}
                                                    onClick={(e) =>
                                                        handleOrderOverview(
                                                            Object.values(interaction.downloadBtn)[1],
                                                            order.project_number
                                                        )
                                                    }
                                                ></button>
                                            )}
                                            <button
                                                className={"button " + interaction.viewBtn.iconClass}
                                                onClick={(e) => {
                                                    handleOrderOverview(
                                                        Object.values(interaction.viewBtn)[1],
                                                        order.project_number
                                                    );
                                                }}
                                            ></button>
                                        </>
                                    )}
                                    {/* Popup to view order */}
                                    {modalOpen[order.project_number] === true && (
                                        <OrderOverviewModal
                                            navigateToPageName={props.navigateToPageName}
                                            index={index}
                                            order={order}
                                            type={"order"}
                                            textData={props.allTextData}
                                        />
                                    )}
                                    {showShippingModal[order.project_number] === true && (
                                        <>
                                            <ShippingOverviewModal
                                                navigateToPageName={props.navigateToPageName}
                                                textData={props.allTextData}
                                                order={order}
                                            />
                                        </>
                                    )}
                                </td>
                            </tr>
                        </Fragment>
                    ))}
                {!!responsive &&
                    !!data &&
                    data.length > 0 &&
                    data.map((order, index) => (
                        <Fragment key={index}>
                            <tr className="card-order-details">
                                <td>
                                    <div className="model-preview-image-container">
                                        <img
                                            width={100}
                                            className="model-preview-image"
                                            src={order.modelImage}
                                            alt="3d-model-preview"
                                        />
                                        {order.models.length > 1 && (
                                            <div className="second">
                                                <img
                                                    width={100}
                                                    className="model-preview-image"
                                                    src={order.models[1].preview_image}
                                                    alt="3d-model-preview"
                                                />
                                                <p>+{order.models.length - 1}</p>
                                            </div>
                                        )}
                                    </div>
                                </td>
                                <td>{order.models.length}</td>
                                <td className="order-info">
                                    <div>{order.project_number}</div>
                                    <div>
                                        <p className="reference">{order.reference}</p>
                                    </div>
                                    <div>{order.orderDate}</div>
                                </td>
                                <td className="order-status">
                                    <Label data={order.status} />
                                </td>
                            </tr>
                            <tr className="horizontal-line-row">
                                <td colSpan="100%">
                                    <hr />
                                </td>
                            </tr>
                            <tr className="card-body-actions">
                                <td className="order-delivery">calc delivery time</td>
                                <td className="order-price">
                                    <>&euro;</>
                                    {getTotalPrice(
                                        order,
                                        loggedInUser,
                                        setTotalDiscount,
                                        mandatoryProductSettings,
                                        productSettings
                                    )}
                                </td>
                                <td className="order-actions" colSpan="100%">
                                    {!!interaction && (
                                        <>
                                            {!!order.status && order.status !== "In productie" && (
                                                <button
                                                    className={"button " + interaction.shippingInfoBtn.iconClass}
                                                    onClick={(e) =>
                                                        handleOrderOverview(
                                                            Object.values(interaction.shippingInfoBtn)[1],
                                                            order.project_number
                                                        )
                                                    }
                                                ></button>
                                            )}
                                            <button
                                                className={"button " + interaction.downloadBtn.iconClass}
                                                onClick={(e) =>
                                                    handleOrderOverview(
                                                        Object.values(interaction.downloadBtn)[1],
                                                        order.project_number
                                                    )
                                                }
                                            ></button>
                                            <button
                                                className={"button " + interaction.viewBtn.iconClass}
                                                onClick={(e) => {
                                                    handleOrderOverview(
                                                        Object.values(interaction.viewBtn)[1],
                                                        order.project_number
                                                    );
                                                }}
                                            ></button>
                                        </>
                                    )}
                                    {/* Popup to view order */}
                                    {modalOpen[order.project_number] === true && (
                                        <OrderOverviewModal
                                            navigateToPageName={props.navigateToPageName}
                                            index={index}
                                            order={order}
                                            type={"order"}
                                            textData={props.allTextData}
                                        />
                                    )}
                                </td>
                            </tr>
                        </Fragment>
                    ))}
            </>
        );
    };

    const SavedQuotationTableData = () => (
        <>
            {!responsive &&
                !!tableData &&
                tableData.length > 0 &&
                tableData.map((quotation, index) => (
                    <tr
                        key={index}
                        className="order-table-row"
                        onClick={(e) => {
                            // Only open popup if popup is not opened yet
                            if (e.target.className.length === 0 || e.target.className === "model-preview-image") {
                                handleOrderOverview(Object.values(interaction.viewBtn)[1], quotation.project_number);
                            }
                        }}
                    >
                        <td>
                            <div className="model-preview-image-container">
                                <img
                                    width={100}
                                    className="model-preview-image"
                                    src={quotation.modelImage}
                                    alt="3d-model-preview"
                                />
                                {quotation.models.length > 1 && (
                                    <div className="second">
                                        <img
                                            width={100}
                                            className="model-preview-image"
                                            src={quotation.models[1].preview_image}
                                            alt="3d-model-preview"
                                        />
                                        <p>+{quotation.models.length - 1}</p>
                                    </div>
                                )}
                            </div>
                        </td>
                        <td>{quotation.models.length}</td>
                        <td>{quotation.project_number}</td>
                        <td>{quotation.orderDate}</td>
                        <td>
                            <p className="reference">{quotation.reference}</p>
                        </td>
                        <td>
                            <Label data={quotation.status} />
                        </td>
                        <td>
                            <>&euro;</>
                            {getTotalPrice(
                                quotation,
                                loggedInUser,
                                setTotalDiscount,
                                mandatoryProductSettings,
                                productSettings
                            )}
                        </td>
                        <td>
                            {!!interaction && (
                                <>
                                    <button
                                        className={"button " + interaction.downloadBtn.iconClass}
                                        onClick={(e) =>
                                            handleOrderOverview(
                                                Object.values(interaction.downloadBtn)[1],
                                                quotation.project_number
                                            )
                                        }
                                    ></button>
                                    <button
                                        className={"button " + interaction.viewBtn.iconClass}
                                        onClick={(e) =>
                                            handleOrderOverview(
                                                Object.values(interaction.viewBtn)[1],
                                                quotation.project_number
                                            )
                                        }
                                    ></button>
                                    <button
                                        className={"button " + interaction.deleteBtn.iconClass}
                                        onClick={(e) =>
                                            handleOrderOverview(
                                                Object.values(interaction.deleteBtn)[1],
                                                quotation.project_number
                                            )
                                        }
                                    ></button>
                                </>
                            )}
                            {modalOpen[quotation.project_number] === true && (
                                <OrderOverviewModal
                                    navigateToPageName={props.navigateToPageName}
                                    index={index}
                                    order={quotation}
                                    type={"savedQuotation"}
                                    textData={props.allTextData}
                                />
                            )}
                        </td>
                    </tr>
                ))}
            {!!responsive &&
                !!tableData &&
                tableData.length > 0 &&
                tableData.map((quotation, index) => (
                    <Fragment key={index}>
                        <tr className="card-order-details">
                            <td>
                                <div className="model-preview-image-container">
                                    <img
                                        width={100}
                                        className="model-preview-image"
                                        src={quotation.modelImage}
                                        alt="3d-model-preview"
                                    />
                                    {quotation.models.length > 1 && (
                                        <div className="second">
                                            <img
                                                width={100}
                                                className="model-preview-image"
                                                src={quotation.models[1].preview_image}
                                                alt="3d-model-preview"
                                            />
                                            <p>+{quotation.models.length - 1}</p>
                                        </div>
                                    )}
                                </div>
                            </td>
                            <td className="order-info">
                                <div>{quotation.orderDate}</div>
                                <div>{quotation.reference}</div>
                            </td>
                            <td className="order-status">
                                <Label data={quotation.status} />
                            </td>
                        </tr>
                        <tr className="horizontal-line-row">
                            <td colSpan="100%">
                                <hr />
                            </td>
                        </tr>
                        <tr className="card-body-actions">
                            <td className="block-delivery">calc delivery time</td>
                            <td className="block-price">
                                <>&euro;</>
                                {getTotalPrice(
                                    quotation,
                                    loggedInUser,
                                    setTotalDiscount,
                                    mandatoryProductSettings,
                                    productSettings
                                )}
                            </td>
                            <td className="block-actions" colSpan="100%">
                                {/*@TODO: change button actions to seperate function */}
                                {!!interaction && (
                                    <>
                                        <button
                                            className={"button " + interaction.downloadBtn.iconClass}
                                            onClick={(e) =>
                                                handleOrderOverview(
                                                    Object.values(interaction.downloadBtn)[1],
                                                    quotation.project_number
                                                )
                                            }
                                        ></button>
                                        <button
                                            className={"button " + interaction.viewBtn.iconClass}
                                            onClick={(e) =>
                                                handleOrderOverview(
                                                    Object.values(interaction.viewBtn)[1],
                                                    quotation.project_number
                                                )
                                            }
                                        ></button>
                                        <button
                                            className={"button " + interaction.deleteBtn.iconClass}
                                            onClick={(e) =>
                                                handleOrderOverview(
                                                    Object.values(interaction.deleteBtn)[1],
                                                    quotation.project_number
                                                )
                                            }
                                        ></button>
                                    </>
                                )}
                                {modalOpen[quotation["project_number"]] === true && (
                                    <OrderOverviewModal
                                        navigateToPageName={props.navigateToPageName}
                                        index={index}
                                        order={quotation}
                                        type={"savedQuotation"}
                                        textData={props.allTextData}
                                        responsive={responsive}
                                    />
                                )}
                            </td>
                        </tr>
                    </Fragment>
                ))}
        </>
    );

    const LibraryTableData = () => (
        <>
            {!responsive &&
                !!tableData &&
                tableData.length > 0 &&
                tableData.map((product, index) => (
                    <tr key={index}>
                        <td>
                            {/* @TODO: Add product 3D model viewer */}
                            <div>
                                <img
                                    width={100}
                                    height={100}
                                    className="model-preview-image"
                                    src={product.preview_image}
                                    alt="3d-model-preview"
                                />
                            </div>
                        </td>
                        <td>{product.name}</td>
                        <td className="label-quotation">
                            <Label data={product.settings} showall={true} />
                        </td>
                        <td>
                            {!!interaction && !!interaction.quantityInput && (
                                <input
                                    onChange={(e) => updateLibraryProductQuantity(product, index, e.target.value)}
                                    type="number"
                                    value={product.quantity}
                                    min={1}
                                />
                            )}
                        </td>
                        <td>&euro;{(product.price * product.quantity).toFixed(2)}</td>
                        <td className="block-actions" colSpan="100%">
                            {!!interaction && (
                                <>
                                    <button
                                        className={"button " + interaction.downloadBtn.iconClass}
                                        onClick={(e) => handleLibraryProductDownload(product)}
                                    ></button>
                                    <button
                                        className={"button " + interaction.cartBtn.iconClass}
                                        onClick={(e) => addLibraryProductToCart(product)}
                                    ></button>
                                </>
                            )}
                        </td>
                    </tr>
                ))}
            {!!responsive &&
                !!data &&
                data.length > 0 &&
                data.map((product, index) => (
                    <Fragment key={index}>
                        <tr>
                            <td>
                                <div>
                                    <img
                                        width={100}
                                        className="model-preview-image"
                                        src={"../../flower_top_view_12-removebg-preview.png"}
                                        alt="3d-model-preview"
                                    />
                                </div>
                            </td>
                            <td>
                                <div>
                                    <span className="model-filename">{product.name}</span>
                                    <div className="label-quotation">
                                        <Label responsive={responsive} data={product.settings} />
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr className="horizontal-line-row">
                            <td colSpan="100%">
                                <hr />
                            </td>
                        </tr>
                        <tr className="card-body-actions">
                            <td colSpan="100%">
                                <div className="quotation-actions">
                                    <div className="quantity-block">
                                        {!!interaction && !!interaction.quantityInput && (
                                            <input
                                                onChange={(e) =>
                                                    handleQuotationModel(
                                                        Object.values(interaction.quantityInput)[0],
                                                        product,
                                                        index,
                                                        e.target.value
                                                    )
                                                }
                                                type="number"
                                                value={product.quantity}
                                                min={1}
                                            />
                                        )}
                                    </div>
                                    <div className="price-block">{product.price}</div>
                                </div>
                            </td>
                        </tr>
                    </Fragment>
                ))}
            {calculatingProduct !== null && (
                <>
                    <div id={"mdl-container-" + calculatingProduct.id} hidden="1"></div>
                    <ModelViewer
                        model={window.location.origin + "/model?type=library_product&id=" + calculatingProduct.id}
                        container={"mdl-container-" + calculatingProduct.id}
                        returnProductDimensions={returnProductDimensions}
                        extension={getExtension(calculatingProduct.file)}
                        productId={calculatingProduct.id}
                    />
                </>
            )}
        </>
    );

    return (
        <tbody>
            {!!rowCheck && rowCheck === "quotation" && <ModelTableData />}
            {!!rowCheck && rowCheck === "orders" && <OrdersTableData />}
            {!!rowCheck && rowCheck === "savedQuotation" && <SavedQuotationTableData />}
            {!!rowCheck && rowCheck === "library" && <LibraryTableData />}
        </tbody>
    );
};

export default TableRow;
