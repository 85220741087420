import React, { useCallback, useState, useRef, useEffect } from "react";
import ModelViewer from "../ModelViewer/ModelViewer";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowUp } from "@fortawesome/pro-regular-svg-icons";
import { useGlobalState } from "../GlobalContext";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { v4 as uuidv4 } from "uuid";

export default function Upload(props) {
    const maxConvertsAtOnce = useRef(8);
    const maxTotalConvertSizeInMb = 300;
    const totalConvertSizeInMb = useRef(0);
    const uploadCountRef = useRef(0);
    const { uploadCount, incrementUploadCount, loggedInUser, setPusherChannels, setUploadProgressionsGlobal } =
        useGlobalState();
    const [viewerQueue, setViewerQueue] = useState([]);
    const [uploadQueue, setUploadQueue] = useState([]);
    const [convertAmount, setConvertAmount] = useState(0);
    const [isBusy, setIsBusy] = useState(false);
    const [progress, setProgress] = useState(0);
    const [viewers, setViewers] = useState([]);
    const formData = useRef([]);
    const [mimes, setMimes] = useState({});
    const endpoint = useRef(window.location.origin + "/upload-models");
    const [config, setConfig] = useState({
        title: "",
        description: "",
        fileTitle: "",
        fileSubtitle: "",
    });

    const getEndpoint = () => {
        if (typeof props.orderId !== "undefined") {
            return endpoint.current + "?order_id=" + props.orderId;
        }
        return endpoint.current;
    };

    useEffect(() => {
        let mimes = {};

        switch (props.type) {
            case "model":
                mimes = {
                    "model/stl": [".stl"],
                    "model/step": [".stp", ".step"],
                    "model/3mf": [".3mf"],
                };
                break;
            case "attachment":
                mimes = {
                    "application/pdf": [".pdf"],
                    "image/jpeg": [".jpg", ".jpeg"],
                    "image/png": [".png"],
                    "application/msword": [".doc", ".docx"],
                };
                endpoint.current = window.location.origin + "/api/upload-attachments";
                break;
            default:
                mimes = {
                    "model/stl": [".stl"],
                    "model/step": [".stp", ".step"],
                    "model/3mf": [".3mf"],
                };
                break;
        }
        if (props.textData !== false) {
            setConfig({
                title: props.textData["upload_section"]["upload-title"],
                description: props.textData["upload_section"]["upload-subtitle"],
                fileTitle: props.textData["upload_section"]["upload-file-title"],
                fileSubtitle: props.textData["upload_section"]["upload-file-subtitle"],
            });
        }
        setMimes(mimes);
    }, [props.type, props.textData]);

    useEffect(() => {
        if (uploadQueue.length > 1) {
            if (typeof props.updatingMultipleModels === "function") {
                props.setUpdatingMultipleModels(true);
            }
        }
        if (uploadQueue.length === 0) {
            if (typeof props.updatingMultipleModels === "function") {
                props.setUpdatingMultipleModels(false);
            }
        }
        if (uploadQueue.length > 0 && convertAmount < maxConvertsAtOnce.current) {
            setConvertAmount((prev) => {
                prev++;
                return prev;
            });
            sendConvertRequest(uploadQueue[0].file, uploadQueue[0].tempId, uploadQueue[0].modelDataBeforeUpload);
            setUploadQueue((prev) => {
                prev.shift();
                return prev;
            });
        }
    }, [uploadQueue, convertAmount]);

    const sendConvertRequest = (file, tempId, modelDataBeforeUpload) => {
        // Send model to be converted
        let frmData = new FormData();
        const channel = "conversion_progress_" + uuidv4();
        const channelObj = {
            channel: channel,
            listening: false,
        };
        setPusherChannels((pusherChannels) => [...pusherChannels, channelObj]);
        frmData.append("channel", channel);
        frmData.append("user_id", loggedInUser.id);
        frmData.append("file", file);

        // Update temporary table row
        props.setModelsData((prev) => {
            let newData = [...prev[0].data];
            newData = newData.filter((item) => item.tempId !== tempId);
            newData.push({
                tempId: tempId,
                newUpload: true,
                section: "uploading",
                channel: channel,
            });
            return [{ ...prev[0], data: newData }];
        });

        // Generate a unique channel name
        axios
            .post(window.location.origin + "/convert-model", frmData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                onUploadProgress: (progressEvent) => {
                    const progress = (progressEvent.loaded / progressEvent.total) * 100;
                    setProgress(progress);
                    setUploadProgressionsGlobal((prevProgressions) => [
                        ...prevProgressions,
                        {
                            progress: progress,
                            tempId: tempId,
                        },
                    ]);
                },
            })
            .then(function (response) {
                incrementUploadCount();
                setConvertAmount((prev) => {
                    prev--;
                    return prev;
                });
                setIsBusy(false);
                setProgress(0);
                uploadCountRef.current--;
                totalConvertSizeInMb.current -= file.size / 1024 / 1024;
                // update maxConvertsAtOnce
                let max = Math.floor(maxTotalConvertSizeInMb / totalConvertSizeInMb.current);
                if (max > 8) {
                    max = 8;
                }
                if (max < 1) {
                    max = 1;
                }
                maxConvertsAtOnce.current = max;
                modelDataBeforeUpload.realId = response.data.model_id;
                props.afterUpload(modelDataBeforeUpload.realId, tempId);
            })
            .catch(function (error) {
                setConvertAmount((prev) => {
                    prev--;
                    return prev;
                });
                setIsBusy(false);
                setProgress(0);
                uploadCountRef.current--;
                totalConvertSizeInMb.current -= file.size / 1024 / 1024;
                // update maxConvertsAtOnce
                let max = Math.floor(maxTotalConvertSizeInMb / totalConvertSizeInMb.current);
                if (max > 8) {
                    max = 8;
                }
                if (max < 1) {
                    max = 1;
                }
                maxConvertsAtOnce.current = max;
                // Remove the temporary table row
                props.setModelsData((prev) => {
                    let newData = [...prev[0].data];
                    newData = newData.filter((item) => item.tempId !== tempId);
                    return [{ ...prev[0], data: newData }];
                });
                // Show error to user
                toast.error(props.textData["upload_section"]["upload-file-error"]);
                console.log(error);
            });
    };

    const onDrop = useCallback((acceptedFiles) => {
        formData.current = [];
        acceptedFiles.forEach((file) => {
            let tempId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
            let modelDataBeforeUpload = { file: file, tempId: tempId };
            let attachmentFormData = new FormData();
            attachmentFormData.append("file", file);
            attachmentFormData.append("tempId", tempId);
            if (props.type === "attachment") {
                axios
                    .post(getEndpoint(), attachmentFormData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                        onUploadProgress: (progressEvent) => {
                            const progress = (progressEvent.loaded / progressEvent.total) * 100;
                            setProgress(progress);
                        },
                    })
                    .then(function (response) {
                        props.setUploadedAttachments((prev) => [...prev, response.data.attachment]);
                        setProgress(0);
                        if (response.status === 200) {
                            // Checkout
                            incrementUploadCount();
                            let container = dragndrop.ref.current;
                            if (props.showUploadedFiles === true) {
                                // Add new element after container
                                let newElement = document.createElement("div");
                                newElement.innerHTML = `<div class="uploaded-file">
                            ${file.name}
                            <button class="btn btn-danger btn-sm" onclick="this.parentNode.remove()">&nbsp;</button>
                        </div>`;
                                container.parentNode.insertBefore(newElement, container.nextSibling);
                            }
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            } else {
                // Convert file to arraybuffer
                if (file.size > 200 * 1024 * 1024) {
                    toast.error(props.textData["upload_section"]["upload-file-too-big"]);
                    return;
                }

                // Add temporary table row
                props.setModelsData((prev) => {
                    let newData = [...prev[0].data];
                    newData = newData.filter((item) => typeof item.section === "undefined");
                    newData.push({
                        tempId: tempId,
                        newUpload: true,
                    });
                    return [{ ...prev[0], data: newData }];
                });

                // Scroll the user down to show the loading spinner
                setTimeout(() => {
                    document
                        .getElementsByClassName(
                            !!props.responsive ? `${props.responsive}table-container` : "table-container"
                        )[0]
                        .scrollTo({ top: 100000, behavior: "smooth" });
                }, 100);

                // If there is too many being converted, add to queue
                if (totalConvertSizeInMb.current + file.size / 1024 / 1024 > maxTotalConvertSizeInMb) {
                    if (uploadCountRef.current <= maxConvertsAtOnce.current) {
                        maxConvertsAtOnce.current = uploadCountRef.current;
                    }
                } else {
                    maxConvertsAtOnce.current = 8;
                }
                totalConvertSizeInMb.current += file.size / 1024 / 1024;
                uploadCountRef.current++;

                setUploadQueue((prev) => [
                    ...prev,
                    { file: file, tempId: tempId, modelDataBeforeUpload: modelDataBeforeUpload },
                ]);
            }
        });
    }, []);

    useEffect(() => {
        if (isBusy === true) return;
        goNextInQueue();
    }, [viewerQueue, isBusy]);

    const goNextInQueue = () => {
        if (viewerQueue.length > 0) {
            setIsBusy(true);
            setViewers((prev) => [...prev, viewerQueue[0]]);
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: mimes,
    });

    const dragndrop = (
        <div {...getRootProps()} className="dragndrop">
            <input {...getInputProps()} />
            <FontAwesomeIcon icon={faFileArrowUp} size="2x" style={{ color: "#022955", marginBottom: "10px" }} />
            <h4>{config.fileTitle}</h4>
            <p>{config.fileSubtitle}</p>
            <div className="progressbar" style={{ width: `${progress}%` }}></div>
        </div>
    );

    function handleScreenshotOnUpload(base64Image, modelid, cachedModel = null, modelDimensions = null) {
        if (formData.current === null) return;
        formData.current.forEach((element) => {
            if (element.tempId === modelid) {
                let frmData = new FormData();
                frmData.append("model_id", element.realId);
                frmData.append("preview", base64Image);
                // Add cached model to the request
                if (cachedModel !== null && typeof cachedModel !== "undefined") {
                    frmData.append("cached_model", JSON.stringify(cachedModel));
                }
                // Add model dimensions to the request
                if (modelDimensions !== null && typeof modelDimensions !== "undefined") {
                    frmData.append("model_dimensions", JSON.stringify(modelDimensions));
                }
                // Check if we need to link the model to the share link (if there is one)
                const params = new URLSearchParams(window.location.search);
                if (params.get("share") !== null && typeof params.get("share") !== "undefined") {
                    frmData.append("share_link", params.get("share"));
                }
                axios
                    .put(window.location.origin + "/update-preview-image", {
                        model_id: element.realId,
                        preview_image: base64Image,
                        cached_model:
                            cachedModel !== null && typeof cachedModel !== "undefined"
                                ? JSON.stringify(cachedModel)
                                : null,
                        model_dimensions:
                            modelDimensions !== null && typeof modelDimensions !== "undefined"
                                ? JSON.stringify(modelDimensions)
                                : null,
                        share_link:
                            params.get("share") !== null && typeof params.get("share") !== "undefined"
                                ? params.get("share")
                                : null,
                    })
                    .then(function (response) {
                        setProgress(0);
                        incrementUploadCount();
                        setViewerQueue((prev) => {
                            prev.shift();
                            return prev;
                        });
                        setConvertAmount((prev) => {
                            prev--;
                            return prev;
                        });
                        setIsBusy(false);
                        props.afterUpload(element.realId, element.tempId);
                    })
                    .catch(function (error) {
                        setProgress(0);
                        incrementUploadCount();
                        setViewerQueue((prev) => {
                            prev.shift();
                            return prev;
                        });
                        setConvertAmount((prev) => {
                            prev--;
                            return prev;
                        });
                        setIsBusy(false);
                        // Remove the temporary table row
                        props.setModelsData((prev) => {
                            let newData = [...prev[0].data];
                            newData = newData.filter((item) => item.tempId !== element.tempId);
                            return [{ ...prev[0], data: newData }];
                        });
                        // Show error to user
                        toast.error(props.textData["upload_section"]["upload-file-error"]);
                        console.log(error);
                    });
            }
        });
    }

    return (
        <>
            {!!props.noContainer && props.noContainer === true && <div className="upload-container">{dragndrop}</div>}
            {!props.noContainer && !props.responsive && !!props.textData && (
                <div className="upload-container">
                    {!!props.textData && (
                        <div className="upload">
                            <Link to={"/"}>
                                <img src={"/Oceanz_logo_nieuw_FC.png"} className="logo" alt="logo" />
                            </Link>
                            <h1>{config.title}</h1>
                            <p>{config.description}</p>
                            {dragndrop}
                        </div>
                    )}
                    {viewers}
                </div>
            )}
            {!props.noContainer && !!props.responsive && !!props.textData && (
                <div className={props.responsive + "upload-container"}>
                    <div className="upload-card-label">{props.textData.title}</div>
                    <div className="upload-card-body">
                        <div className="card-title">{config.title}</div>
                        <div className="card-content">
                            <p>{config.description}</p>
                            {dragndrop}
                        </div>
                    </div>
                    {viewers}
                </div>
            )}
        </>
    );
}
