import React, { useEffect, useState, useRef } from "react";
import echo from "../../laravel-echo/echo";
import { useGlobalState } from "../GlobalContext";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const FileConversionProgress = ({ uploadProgressions, setUploadProgressions, model }) => {
    const { pusherChannels, setPusherChannels, uploadProgressionsGlobal } = useGlobalState();
    const [uploadStage, setUploadStage] = useState(
        uploadProgressions[model?.channel] ? uploadProgressions[model?.channel].stage : "Uploading"
    );
    const [uploadProgress, setUploadProgress] = useState(
        uploadProgressions[model?.channel] ? uploadProgressions[model?.channel].progress : 0
    );
    const prevProgressRef = useRef(uploadProgress);

    useEffect(() => {
        if (uploadProgress === 0) {
            uploadProgressionsGlobal.forEach((progression) => {
                if (progression.tempId === model?.tempId && uploadStage === "Uploading") {
                    setUploadProgress(Math.round(progression.progress * 0.2)); // Max progress is 20%
                    prevProgressRef.current = Math.round(progression.progress * 0.2);
                }
            });
        }
    }, [uploadProgressionsGlobal]);

    useEffect(() => {
        // Debounce each update and if it takes more than 1 second, animate the progress
        const increment = Math.random() * 5;
        const debounce = setTimeout(() => {
            if (prevProgressRef.current < 98) {
                if (increment > 1) {
                    animateProgress(prevProgressRef.current, prevProgressRef.current + increment, model?.channel, {
                        stage: uploadStage,
                    });
                }
            }
        }, 750);

        return () => {
            clearTimeout(debounce);
        };
    }, [uploadProgress]);

    const animateProgress = (start, end, channel, message) => {
        const duration = 1000; // 1 second
        const startTime = performance.now();

        const updateProgress = (currentTime) => {
            const elapsedTime = currentTime - startTime;
            const progressPercentage = Math.min(elapsedTime / duration, 1);
            const currentProgress = start + (end - start) * progressPercentage;
            const currentProgressInt = parseInt(currentProgress);

            setUploadProgressions((prevProgressions) => {
                return {
                    ...prevProgressions,
                    [channel]: {
                        progress: currentProgressInt,
                        stage: message.stage,
                    },
                };
            });
            setUploadProgress(currentProgress);
            prevProgressRef.current = currentProgress;

            if (progressPercentage < 1) {
                requestAnimationFrame(updateProgress);
            }
        };

        requestAnimationFrame(updateProgress);
    };

    useEffect(() => {
        // Array to hold cleanup functions
        const cleanupFunctions = [];
        // Loop through pusherChannels
        pusherChannels.forEach((channelObj, index) => {
            if (!channelObj.listening && model?.channel === channelObj.channel) {
                const subscription = echo
                    .channel(`file-conversion.${channelObj.channel}`)
                    .listen("FileConversionProgress", (e) => {
                        // Parse message as json
                        const message = JSON.parse(e.message);
                        console.log("Progress message:", message);

                        // parse only numbers from the string
                        const progress = parseInt(message.conversion_progress.replace(/\D/g, ""));

                        // First update is from converting so it is already 20%
                        if (prevProgressRef.current === 0) {
                            prevProgressRef.current = 20;
                        }

                        animateProgress(prevProgressRef.current, progress, channelObj.channel, message);
                        setUploadStage(message.stage);
                        prevProgressRef.current = progress;

                        // If event is 100%, stop listening
                        if (message.conversion_progress === "100%") {
                            console.log("File conversion complete");
                            // Remove the channel from the list of channels
                            setPusherChannels((prevChannels) => {
                                const newChannels = [...prevChannels];
                                newChannels.splice(index, 1);
                                return newChannels;
                            });
                            // Unsubscribe from the channel
                            subscription.stopListening("FileConversionProgress");
                        }
                    });

                // Mark the channel as listening
                setPusherChannels((prevChannels) => {
                    const newChannels = [...prevChannels];
                    newChannels[index].listening = true;
                    return newChannels;
                });

                // Add cleanup function for this subscription
                cleanupFunctions.push(() => {
                    // subscription.stopListening('FileConversionProgress');
                });
            }
        });

        // Cleanup function to stop listening when the component unmounts or dependencies change
        return () => {
            cleanupFunctions.forEach((cleanup) => cleanup());
        };
    }, [pusherChannels]);

    return (
        <div className="progress-bar" style={{ width: "75px", height: "75px", marginBottom: "15px" }}>
            <CircularProgressbar
                value={uploadProgress}
                text={`${uploadProgress}%`}
                styles={buildStyles({
                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: "butt",

                    // Text size
                    textSize: "18px",

                    // How long animation takes to go from one percentage to another, in seconds
                    pathTransitionDuration: 1,

                    // Can specify path transition in more detail, or remove it entirely
                    // pathTransition: 'none',

                    // Colors
                    pathColor: `#082756`,
                    textColor: "#082756",
                    trailColor: "#d6d6d6",
                    backgroundColor: "#3e98c7",
                })}
            />
            <p>{uploadStage}</p>
        </div>
    );
};

export default React.memo(FileConversionProgress);
