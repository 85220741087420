import { useState, useEffect, useRef, Fragment } from "react";
import ProductSettings from "./ProductSettings/ProductSettings";
import Table from "./Table/Table";
import NavigationMenu from "./NavigationMenu";
import Upload from "./Upload/Upload";
import QuotationBlock from "./ContentBlock/QuotationBlock";
import axios from "axios";
import Sidebar from "./Sidebar";
import OrderOverview from "./Account/OrderOverview";
import QuotationOverview from "./Account/QuotationOverview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-regular-svg-icons";
import { useGlobalState } from "./GlobalContext";
import { useMediaQuery } from "react-responsive";
import ProductLibraryOverview from "./Account/ProductLibraryOverview";
import Settings from "./Account/Settings";
import Details from "./Account/Details";
import { useLocation } from "react-router-dom";
import LoginPopup from "./Popups/LoginPopup";
import { updateModelColor } from "./ModelViewer/ColorUpdater";
import RetrieveProductSettings from "./ProductSettings/RetrieveProductSettings";
import { getSettingsId } from "./ProductSettings/Helper";

export default function Home(props) {
    // props from react-router-dom in app.jsx
    const [modelsData, setModelsData] = useState();
    const { uploadCount } = useGlobalState();
    const [textData, setTextData] = useState(null);
    const { loggedInUser } = useGlobalState();
    const { activeNavigationMenuItem, setActiveNavigationMenuItem } = useGlobalState();
    const lastUploadedModels = useRef([]);
    // const [activeMenuItem, setActiveMenuItem] = useState(selectedMenuItem);
    const [hasModelsSelected, setHasModelsSelected] = useState(false);
    const { productSettings, mandatoryProductSettings, setProductSettings, setMandatoryProductSettings, currentPage } =
        useGlobalState();
    const [loginModal, setLoginModal] = useState(false);
    const updatingPrices = useRef(false);
    const [updatingMultipleModels, setUpdatingMultipleModels] = useState(false);
    const updateNextIteration = useRef(false);
    const responsiveClass = "rsp-";
    const { state } = useLocation();
    const [saveSettingsFooter, setSaveSettingsFooter] = useState(null);
    const [productsData, setProductsData] = useState(JSON.parse(window.localStorage.getItem("productsData")));
    const defaultPreviewImage =
        import.meta.env.VITE_DEFAULT_PREVIEW_IMAGE ||
        "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="; // Blank base64 image
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1150px)" });

    useEffect(() => {
        RetrieveProductSettings({ setMandatoryProductSettings, setProductSettings });
        setTextData(props.textData);

        if (currentPage !== "home") {
            props.initLoadPage();
        }
    }, []);

    useEffect(() => {
        if (updatingMultipleModels) {
            updatePricesForAllModels();
        }
    }, [updatingMultipleModels]);

    useEffect(() => {
        if (uploadCount > 0 && lastUploadedModels.current.length > 0) {
            getModelDataFromApi();
        } else {
            // get modelsData from localstorage
            const models = JSON.parse(window.localStorage.getItem("modelsData"));
            if (models !== null && models.length > 0) {
                models[0].data.forEach((model) => {
                    if (model.delivery === "...") {
                        updateShippingCosts(model);
                    }
                });
                setModelsData(models);
            } else {
                getModelDataFromApi();
            }
        }
    }, [uploadCount]);

    function getModelDataFromApi() {
        mapModelsToData()
            .then((data) => {
                if (typeof data !== "undefined" && data !== null) {
                    // Check if data is an array
                    if (Array.isArray(data)) {
                        setModelsData([
                            {
                                section: "quotation",
                                data: data,
                            },
                        ]);
                    } else {
                        setModelsData((prev) => {
                            const model = data;
                            if (prev[0] && Array.isArray(prev[0].data)) {
                                // Update model data
                                let newState = prev[0].data.map((stateModel) => {
                                    if (stateModel.tempId === model.tempId && typeof model.tempId !== "undefined") {
                                        delete model.tempId;
                                        return model; // return new object instead of modifying state
                                    }
                                    return stateModel;
                                });
                                localStorage.setItem("modelsData", JSON.stringify([{ ...prev[0], data: newState }]));
                                return [{ ...prev[0], data: newState }];
                            } else {
                                return [
                                    {
                                        section: "quotation",
                                        data: [model],
                                    },
                                ];
                            }
                        });
                    }
                }
            })
            .catch((error) => {
                console.error("Error retrieving mapModelsToData:", error);
            });

        // Make a GET request for the uploaded models
        // const modelDataAPI = window.location.origin + "/models";
        // axios
        //     .get(modelDataAPI)
        //     .then((response) => {
        //         mapModelsToData(response.data)
        //             .then((data) => {
        //                 if (data !== null) {
        //                     setModelsData([
        //                         {
        //                             section: "quotation",
        //                             data: data,
        //                         },
        //                     ]);
        //                 }
        //                 // if (!loadedInitModelColors.current && data !== null) {
        //                 //     loadModelColors(data, null, true);
        //                 //     loadedInitModelColors.current = true;
        //                 // }
        //             })
        //             .catch((error) => {
        //                 console.error("Error retrieving mapModelsToData:", error);
        //             });
        //     })
        //     .catch((error) => {
        //         console.error(error);
        //     });
    }

    const mapModelsToData = async () => {
        // Check if data in local storage
        let localModelsData = JSON.parse(localStorage.getItem("modelsData")) || [];
        localModelsData = typeof localModelsData[0]?.data !== "undefined" ? localModelsData[0].data : [];
        let defaultSettings = {
            application: "Retail & Industrial",
            material: "Oceanz PA12",
            color: "No colouring",
            polish: "No",
            coating: "No",
            orientation: "unlocked",
            sterile: null,
            intendedUse: null,
            clinicalUse: null,
            custom: null,
            case: null,
            practitioner: null,
        };

        // Check if current logged in user default settings are set
        // if (loggedInUser?.user !== null && typeof loggedInUser?.user !== "undefined") {
        //     if (
        //         loggedInUser?.user?.default_settings !== null &&
        //         typeof loggedInUser?.user?.default_settings !== "undefined"
        //     ) {
        //         defaultSettings = {
        //             application: loggedInUser.user.default_settings.application,
        //             material: loggedInUser.user.default_settings.material,
        //             color: loggedInUser.user.default_settings.color,
        //             polish: loggedInUser.user.default_settings.polish,
        //             coating: loggedInUser.user.default_settings.coating,
        //             orientation: loggedInUser.user.default_settings.orientation,
        //             sterile:
        //                 loggedInUser.user.default_settings.sterile === "No"
        //                     ? null
        //                     : loggedInUser.user.default_settings.sterile,
        //             intendedUse: loggedInUser.user.default_settings.intendedUse,
        //             clinicalUse: loggedInUser.user.default_settings.clinicalUse,
        //             custom: loggedInUser.user.default_settings.custom,
        //         };
        //     }
        // }

        // If a new model is uploaded, get the new model data from API
        if (lastUploadedModels.current.length > 0) {
            let newUpload = lastUploadedModels.current.pop();
            // Retrieve the model
            const modelDataAPI = window.location.origin + "/api/singleModel?id=" + newUpload.id;
            let model = await axios.get(modelDataAPI).then((response) => {
                // Set all default values
                let result = {
                    tempId: newUpload.tempId,
                    id: newUpload.id,
                    path: response.data.path,
                    glb_path: response.data.glb_path,
                    file: response.data.file,
                    image:
                        typeof response.data.preview_image !== "undefined" &&
                        response.data.preview_image !== null &&
                        response.data.preview_image !== ""
                            ? response.data.preview_image
                            : defaultPreviewImage,
                    settings: defaultSettings,
                    cached_model: response.data.cached_model,
                    delivery: "...",
                    amount: 1,
                    price: "Op aanvraag",
                    loading: false,
                };
                return result;
            });

            setModelsData((prev) => {
                // Check if prev[0] and prev[0].data exist
                if (prev[0] && Array.isArray(prev[0].data)) {
                    // Update model data
                    let newState = prev[0].data.map((stateModel) => {
                        if (stateModel.id === model.id) {
                            console.log(stateModel, model);
                            return model; // return new object instead of modifying state
                        }
                        return stateModel;
                    });

                    localStorage.setItem("modelsData", JSON.stringify([{ ...prev[0], data: newState }]));
                    localModelsData = [{ ...prev[0], data: newState }];
                    return [{ ...prev[0], data: newState }];
                } else {
                    localModelsData = [
                        {
                            section: "quotation",
                            data: [model],
                        },
                    ];
                    return localModelsData;
                }
            });
            // newUploadedModels.push(result);
            updatePrice(model);
            updateShippingCosts(model);

            // return promise
            return new Promise((resolve, reject) => {
                resolve(model);
            });
        }

        return new Promise((resolve, reject) => {
            resolve(localModelsData);
        });
    };

    const savedQuotationData = [
        {
            section: "savedQuotation",
            data: [
                {
                    creation_date: "20-02-2023",
                    reference: "Tandarts nijkerk 19",
                    status: "Offerte",
                    price: "59,41",
                },
            ],
        },
    ];

    const orderData = [
        {
            section: "orders",
            orders: [
                {
                    projectnr: 2023001,
                    orderDate: "03-02-2023",
                    reference: "Tandarts nijkerk 19",
                    status: "In productie",
                    price: "59,41",
                },
                {
                    projectnr: 2024001,
                    orderDate: "05-02-2023",
                    reference: "Knieprothese",
                    status: "Verzonden",
                    price: "59,41",
                },
                {
                    projectnr: 2025001,
                    orderDate: "12-06-2023",
                    reference: "Schaakstukken",
                    status: "Afgerond",
                    price: "59,41",
                },
            ],
        },
    ];

    const [orders, setOrders] = useState(orderData);
    const [savedQuotations, setSavedQuotations] = useState(savedQuotationData);

    // This useEffect is used to set the active menu item in the primary menu based on the current url. This is necessary if user is using
    // forward and back buttons in the browser.
    useEffect(() => {
        setActiveNavigationMenuItem(props.selectedMenuItem);
    }, [props.selectedMenuItem]);

    // This useEffect is used to set a scrollable page for the quotation section.
    useEffect(() => {
        const appContainer = document.getElementById("app");
        if (activeNavigationMenuItem === "navigate-quotation" && isTabletOrMobile) {
            appContainer.style.overflowY = "scroll";
            appContainer.style.height = "calc(95vh - 100px)";
        } else {
            appContainer.style.overflowY = "auto";
            appContainer.style.height = "100%";
        }
    }, [activeNavigationMenuItem, window.innerWidth]);

    // Callback function to receive updated settings from the product settings component.
    function updateModelSettings(config) {
        setModelsData((prevState) => {
            const newData = prevState[0].data.map((model) => {
                if (model.checked === true) {
                    return { ...model, settings: Object.assign({}, config) };
                } else {
                    return model;
                }
            });
            let result = [{ ...prevState[0], data: newData }];
            return result;
        });
    }

    // Update the left part of screen with either the product settings or the upload component.
    useEffect(() => {
        if (updateNextIteration.current === true) {
            updatePrices();
            updateNextIteration.current = false;
        }

        let result = false;
        if (modelsData !== undefined && modelsData.length > 0) {
            if (modelsData[0].data !== undefined && modelsData[0].data.length > 0) {
                modelsData[0].data.forEach((model) => {
                    if (model.checked === true) {
                        result = true;
                    }
                });
            }
        }
        setHasModelsSelected(result);
    }, [modelsData]);

    // Handle the screenshot from the modelviewer and update the model in the database.
    function handleScreenshot(imageData, modelId, color) {
        // Set the model data locally
        setModelsData((prevState) => {
            const newData = prevState[0].data.map((model) => {
                if (model.id === modelId) {
                    model.image = imageData;
                    model.color = color;
                    model.loading = false;
                }
                return model;
            });
            let result = [{ ...prevState[0], data: newData }];
            return result;
        });
    }

    function updateSelectedModelsColor(color) {
        // Get all checked models
        if (typeof modelsData !== "undefined" && modelsData.length > 0) {
            if (typeof modelsData[0].data !== "undefined" && modelsData[0].data.length > 0) {
                // Update localstate
                setModelsData((prevState) => {
                    let models = [];
                    const newData = prevState[0].data.map((model) => {
                        if (model.checked) {
                            model.color = color;
                            model.loading = true; // set loading to true in order to show spinning loader
                            models.push(model);
                        }
                        return { ...model };
                    });
                    const result = [{ ...prevState[0], data: newData }];
                    //loadModelColors(models);
                    window.localStorage.setItem("modelsData", JSON.stringify(result)); // set local storage
                    return result;
                });
            }
        }
    }

    const updateModelDeliveryTime = (value) => {
        if (typeof modelsData !== "undefined" && modelsData.length > 0) {
            if (typeof modelsData[0].data !== "undefined" && modelsData[0].data.length > 0) {
                setModelsData((prevState) => {
                    const newData = prevState[0].data.map((model) => {
                        if (model.checked === true) {
                            return { ...model, delivery: value };
                        }
                        return { ...model };
                    });
                    const result = [{ ...prevState[0], data: newData }];
                    window.localStorage.setItem("modelsData", JSON.stringify(result)); // set local storage
                    return result;
                });
            }
        }
    };

    const updateModelOrientation = (value) => {
        if (typeof modelsData !== "undefined" && modelsData.length > 0) {
            if (typeof modelsData[0].data !== "undefined" && modelsData[0].data.length > 0) {
                //loadModelColors(modelsData[0].data, value);
            }
        }
    };

    function saveProductSettings(chosenConfig) {
        // Check how many models are selected
        let selectedModels = 0;
        if (typeof modelsData !== "undefined" && modelsData.length > 0) {
            if (typeof modelsData[0].data !== "undefined" && modelsData[0].data.length > 0) {
                modelsData[0].data.forEach((model) => {
                    if (model.checked === true) {
                        selectedModels++;
                    }
                });
            }
        }

        if (selectedModels > 1) {
            updatePricesForAllModels(modelsData[0].data);
        }

        // Unselect all models.
        document.getElementsByClassName("checkbox--select-all")[0].checked = false;
        setModelsData((prevState) => {
            const newData = prevState[0].data.map((model) => {
                if (model.checked === true) {
                    return { ...model, checked: false, settings: chosenConfig };
                }
                return { ...model, checked: false };
            });
            const result = [{ ...prevState[0], data: newData }];
            window.localStorage.setItem("modelsData", JSON.stringify(result)); // set local storage

            return result;
        });
    }

    function afterUpload(realId, tempId) {
        lastUploadedModels.current.push({ id: realId, tempId: tempId });
    }

    const updatePricesForAllModels = (models = null) => {
        // Set model state to loading
        setModelsData((prevState) => {
            const newData = prevState[0].data.map((model) => {
                if (model.checked === true) {
                    model.loadingPrice = true;
                }
                return model;
            });
            return [{ ...prevState[0], data: newData }];
        });

        let modelIds = [];
        if (models === null) {
            // Update the prices for all models in one single call
            if (typeof modelsData !== "undefined" && modelsData.length > 0) {
                if (typeof modelsData[0].data !== "undefined" && modelsData[0].data.length > 0) {
                    modelsData[0].data.forEach((model) => {
                        if (model.checked === true) {
                            modelIds.push({
                                id: model.id,
                                settings: getSettingsId(model.settings, productSettings),
                                amount: model.amount,
                            });
                        }
                    });
                }
            }
        } else {
            models.forEach((model) => {
                if (model.checked === true) {
                    modelIds.push({
                        id: model.id,
                        settings: getSettingsId(model.settings, productSettings),
                        amount: model.amount,
                    });
                }
            });
        }

        if (modelIds.length > 0) {
            axios
                .post(window.location.origin + `/api/all-product-prices`, {
                    models: modelIds,
                })
                .then((response) => {
                    if (typeof response.data.data !== "undefined") {
                        const modelLookup = modelsData[0].data.reduce((acc, model) => {
                            acc[model.id] = model;
                            return acc;
                        }, {});

                        response.data.data.forEach((element) => {
                            const model = modelLookup[element.model_id];
                            if (model) {
                                model.price = parseFloat(element.unit_price);
                                model.discount_multiplier = element.discount_multiplier;
                                setModelsData((prevState) => {
                                    const newData = prevState[0].data.map((stateModel) => {
                                        if (stateModel.id === model.id) {
                                            return {
                                                ...stateModel,
                                                price: model.price,
                                                loadingPrice: false,
                                                discount_multiplier: model.discount_multiplier,
                                            };
                                        }
                                        return stateModel;
                                    });
                                    const result = [{ ...prevState[0], data: newData }];
                                    window.localStorage.setItem("modelsData", JSON.stringify(result)); // set local storage
                                    return result;
                                });
                            }
                        });
                    }
                })
                .catch((error) => {
                    setModelsData((prevState) => {
                        const newData = prevState[0].data.map((model) => {
                            if (model.checked === true) {
                                model.loadingPrice = false;
                                model.price = "Op aanvraag";
                            }
                            return model;
                        });
                        return [{ ...prevState[0], data: newData }];
                    });
                });
        }
        setUpdatingMultipleModels(false);
    };

    // Update a single model price
    const updatePrice = (element) => {
        // Set model state to loading
        setModelsData((prevState) => {
            const newData = prevState[0].data.map((model) => {
                if (model.id === element.id) {
                    model.loadingPrice = true;
                }
                return model;
            });
            return [{ ...prevState[0], data: newData }];
        });

        let params = "";
        let found = false;
        for (let setting in element.settings) {
            if (setting === "intendedUse") {
                found = true;
            }

            if (
                element.settings[setting] !== "" &&
                element.settings[setting] !== null &&
                typeof element.settings[setting] !== "undefined"
            ) {
                params +=
                    "&" + encodeURIComponent(`${setting}`) + "=" + encodeURIComponent(`${element.settings[setting]}`);
            } else {
                params += "&" + encodeURIComponent(`${setting}`) + "=null";
            }
        }
        if (!found) {
            params += "&intendedUse=null";
        }
        axios
            .get(window.location.origin + `/api/product-price?model_id=${element.id}${params}&amount=${element.amount}`)
            .then((response) => {
                // Cant get price, show default
                if (typeof response.data.message === "undefined" && typeof response.data.data.length === "undefined") {
                    element.price = "Op aanvraag";
                } else {
                    // Loop through the returned models and update the price
                    response.data.data.forEach((item) => {
                        if (item.model_id === element.id) {
                            element.price = item.unit_price;
                            element.discount_multiplier = item.discount_multiplier;
                        }
                    });
                }
                setModelsData((prevState) => {
                    const newData = prevState[0].data.map((model) => {
                        if (model.id === element.id) {
                            model.price = element.price;
                            model.loadingPrice = false;
                            model.discount_multiplier = element.discount_multiplier;
                        }
                        return model;
                    });
                    const result = [{ ...prevState[0], data: newData }];
                    window.localStorage.setItem("modelsData", JSON.stringify(result)); // set local storage
                    return result;
                });
            })
            .catch((error) => {
                element.loadingPrice = false;
                setModelsData((prevState) => {
                    const newData = prevState[0].data.map((model) => {
                        if (model.id === element.id) {
                            model.loadingPrice = false;
                            model.price = "Op aanvraag";
                        }
                        return model;
                    });
                    const result = [{ ...prevState[0], data: newData }];
                    window.localStorage.setItem("modelsData", JSON.stringify(result)); // set local storage
                    return result;
                });
            });
        return element;
    };

    const updatePricesOnNextIteration = () => {
        console.log("next iter");
        if (updatingMultipleModels !== false) {
            console.log("abcd");
            return;
        }
        // Update the prices the next time modelsData gets updated.
        updateNextIteration.current = true;
    };

    // Update all selected models
    const updatePrices = (selectedOnly = true) => {
        if (updatingPrices.current === true) return;
        updatingPrices.current = true;
        let selectedModels = [];
        if (selectedOnly) {
            if (typeof modelsData !== "undefined" && modelsData.length > 0) {
                if (typeof modelsData[0].data !== "undefined" && modelsData[0].data.length > 0) {
                    selectedModels = modelsData[0].data.filter((model) => model.checked === true);
                }
            }
        } else {
            selectedModels = modelsData[0].data;
        }

        if (modelsData[0].data.length === 0) {
            let models = JSON.parse(localStorage.getItem("modelsData"));
            if (typeof models === "undefined" || models === null || models.length === 0) {
                updatingPrices.current = false;
                return;
            } else {
                setModelsData((prev) => {
                    return [{ data: models, section: "quotation" }];
                });
            }
        }

        // Get new price
        selectedModels.map((element) => {
            return updatePrice(element);
        });

        setTimeout(() => {
            updatingPrices.current = false;
        }, 500);
    };

    const updateShippingCosts = async (model, fromTableRow = false) => {
        if (typeof modelsData !== "undefined" && modelsData[0].data.length === 0) return;

        // Show the model loading
        if (fromTableRow) {
            setModelsData((prevState) => {
                const newData = prevState[0].data.map((stateModel) => {
                    if (stateModel.id === model.id) {
                        stateModel.delivery = "...";
                    }
                    return stateModel;
                });

                let result = [{ ...prevState[0], data: newData }];
                return result;
            });
        }

        if (getSettingsId(model.settings, productSettings) !== "") {
            axios
                .post(window.location.origin + `/api/calculate-shipping-time`, {
                    model_id: model.id,
                    country: "Netherlands",
                    settings_id: getSettingsId(model.settings, productSettings),
                    amount: model.amount,
                })
                .then((response) => {
                    setModelsData((prevState) => {
                        const newData = prevState[0].data.map((stateModel) => {
                            if (
                                stateModel.id === model.id &&
                                response.data !== null &&
                                response.data.delivery_time !== undefined
                            ) {
                                stateModel.delivery = parseInt(response.data.delivery_time);
                            }
                            return stateModel;
                        });

                        let result = [{ ...prevState[0], data: newData }];
                        return result;
                    });
                });
        }
    };

    const getAmountOfSelectedModels = () => {
        if (typeof modelsData !== "undefined" && modelsData.length > 0) {
            if (typeof modelsData[0].data !== "undefined" && modelsData[0].data.length > 0) {
                let selectedModels = modelsData[0].data.filter((model) => model.checked === true);
                return selectedModels.length;
            }
        }
        return 0;
    };

    const isSafari = (naviUserAgent = navigator.userAgent) => {
        if (/^((?!Chrome|CriOS|FxiOS|EdgiOS|OPiOS|mercury).)*Safari/i.test(naviUserAgent)) {
            return "isSClass";
        } else {
            return "";
        }
    };

    return (
        <>
            {!isTabletOrMobile && (
                <div className="root">
                    {activeNavigationMenuItem === "navigate-quotation" && !!modelsData && (
                        <>
                            {!props.authenticated && loginModal && (
                                <LoginPopup
                                    textData={!!textData && textData["popups/login"]}
                                    toggleLoginModal={setLoginModal}
                                    navigateToPageName={props.navigateToPageName}
                                />
                            )}
                            <div className="screen-left">
                                {!hasModelsSelected && (
                                    <Upload
                                        textData={!!textData && textData["sections/quotation"]}
                                        afterUpload={afterUpload}
                                        setModelsData={setModelsData}
                                        setUpdatingMultipleModels={setUpdatingMultipleModels}
                                    />
                                )}
                                {hasModelsSelected && (
                                    <ProductSettings
                                        modelsData={modelsData}
                                        updateModelSettings={updateModelSettings}
                                        textData={!!textData && textData}
                                        saveProductSettings={saveProductSettings}
                                        updateSelectedModelsColor={updateSelectedModelsColor}
                                        loggedInUser={loggedInUser}
                                        updateModelColor={updateModelColor}
                                        updateModelDeliveryTime={updateModelDeliveryTime}
                                        updateModelOrientation={updateModelOrientation}
                                        updatePricesOnNextIteration={updatePricesOnNextIteration}
                                        productSettings={productSettings}
                                        setSaveFooter={setSaveSettingsFooter}
                                        saveFooter={saveSettingsFooter}
                                        setUpdatingMultipleModels={setUpdatingMultipleModels}
                                    />
                                )}
                                <QuotationBlock textData={!!textData && textData["content_blocks/quotation"]} />
                            </div>
                            <div className="screen-right">
                                <div className="header">
                                    <div>
                                        <h1>{!!textData && textData["sections/quotation"].title}</h1>
                                        {getAmountOfSelectedModels() > 0 && (
                                            <>
                                                <span className="amount-of-models-icon"></span>
                                                <span className="amount-of-models">
                                                    {getAmountOfSelectedModels() + " "}
                                                    {!!textData && textData["header"].of}
                                                    {" " + modelsData[0].data.length + " "}
                                                    {!!textData && textData["header"].selected}
                                                </span>
                                            </>
                                        )}
                                    </div>
                                    <NavigationMenu
                                        textData={!!textData && textData}
                                        authenticated={props.authenticated}
                                        toggleLoginModal={setLoginModal}
                                        navigateToPageName={props.navigateToPageName}
                                    />
                                </div>
                                <Table
                                    data={!!modelsData && modelsData}
                                    productsData={!!productsData && productsData}
                                    setProductsData={setProductsData}
                                    onModelChange={setModelsData}
                                    textData={!!textData && textData["sections/quotation"]}
                                    textDataLabel={!!textData && textData["sections/label"]}
                                    checkoutState={!!state && state}
                                    popupData={!!textData && textData["popups/save_quotation"]}
                                    loginData={!!textData && textData["popups/login"]}
                                    mandatoryProductOptions={mandatoryProductSettings}
                                    navigateToPageName={props.navigateToPageName}
                                    getPageUrlByName={props.getPageUrlByName}
                                    allTextData={!!textData && textData}
                                    updateShippingCosts={updateShippingCosts}
                                    updatePrice={updatePrice}
                                    setSaveFooterSettings={setSaveSettingsFooter}
                                />
                            </div>
                        </>
                    )}
                    {activeNavigationMenuItem === "navigate-account" && (
                        <>
                            <div className="screen-left">
                                <Sidebar getPageUrlByName={props.getPageUrlByName} textData={!!textData && textData} />
                            </div>
                            <div className={`screen-right ${isSafari()}`}>
                                <div className="header">
                                    <div className="header-title">
                                        {(props.currentPage === "account_orders" ||
                                            props.currentPage === "accountsettings") && (
                                            <>
                                                <FontAwesomeIcon icon={faUser} size="xl" />
                                                <h1>{props.title}</h1>
                                            </>
                                        )}
                                    </div>
                                    <NavigationMenu
                                        textData={!!textData && textData}
                                        authenticated={props.authenticated}
                                        navigateToPageName={props.navigateToPageName}
                                    />
                                </div>
                                {props.currentPage === "account_orders" && (
                                    <OrderOverview
                                        navigateToPageName={props.navigateToPageName}
                                        orderData={orders}
                                        textData={!!textData && textData}
                                    />
                                )}
                                {props.currentPage === "account_quotations" && (
                                    <QuotationOverview
                                        navigateToPageName={props.navigateToPageName}
                                        quotationData={savedQuotations}
                                        textData={!!textData && textData}
                                        loggedInUser={loggedInUser}
                                    />
                                )}
                                {props.currentPage === "product_library" && (
                                    <ProductLibraryOverview
                                        setProductsData={setProductsData}
                                        textData={!!textData && textData}
                                        navigateToPageName={props.navigateToPageName}
                                    />
                                )}
                                {props.currentPage === "account_settings" && (
                                    <Settings
                                        navigateToPageName={props.navigateToPageName}
                                        textData={!!textData && textData}
                                    />
                                )}
                                {props.currentPage === "account_details" && (
                                    <Details
                                        navigateToPageName={props.navigateToPageName}
                                        textData={!!textData && textData}
                                    />
                                )}
                            </div>
                        </>
                    )}
                </div>
            )}
            {isTabletOrMobile && (
                <div
                    className="root"
                    style={activeNavigationMenuItem === "navigate-account" ? { overflowY: "auto", height: "auto" } : {}}
                >
                    {activeNavigationMenuItem === "navigate-quotation" && !!modelsData && (
                        <>
                            <div className={responsiveClass + "header"}>
                                <NavigationMenu
                                    textData={!!textData && textData}
                                    authenticated={props.authenticated}
                                    navigateToPageName={props.navigateToPageName}
                                    responsive={responsiveClass}
                                    toggleLoginModal={setLoginModal}
                                />
                            </div>
                            {!hasModelsSelected && (
                                <Upload
                                    responsive={responsiveClass}
                                    textData={!!textData && textData["sections/quotation"]}
                                    afterUpload={afterUpload}
                                    setModelsData={setModelsData}
                                />
                            )}
                            {hasModelsSelected && (
                                <ProductSettings
                                    modelsData={modelsData}
                                    updateModelSettings={updateModelSettings}
                                    textData={!!textData && textData}
                                    saveProductSettings={saveProductSettings}
                                    loggedInUser={loggedInUser}
                                    updateModelColor={updateModelColor}
                                    productSettings={productSettings}
                                    updateSelectedModelsColor={updateSelectedModelsColor}
                                    updateModelDeliveryTime={updateModelDeliveryTime}
                                    updateModelOrientation={updateModelOrientation}
                                    updatePricesOnNextIteration={updatePricesOnNextIteration}
                                    saveFooter={saveSettingsFooter}
                                    setSaveFooter={setSaveSettingsFooter}
                                />
                            )}
                            <Table
                                responsive={responsiveClass}
                                data={!!modelsData && modelsData}
                                onModelChange={setModelsData}
                                textData={!!textData && textData["sections/quotation"]}
                                textDataLabel={!!textData && textData["sections/label"]}
                                navigateToPageName={props.navigateToPageName}
                                getPageUrlByName={props.getPageUrlByName}
                                allTextData={!!textData && textData}
                                updateShippingCosts={updateShippingCosts}
                                updatePrice={updatePrice}
                                checkoutState={!!state && state}
                                popupData={!!textData && textData["popups/save_quotation"]}
                                loginData={!!textData && textData["popups/login"]}
                                mandatoryProductOptions={mandatoryProductSettings}
                                setSaveFooterSettings={setSaveSettingsFooter}
                            />
                        </>
                    )}
                    {activeNavigationMenuItem === "navigate-account" && !!orders && (
                        <>
                            <div className={responsiveClass + "header"}>
                                <NavigationMenu
                                    textData={!!textData && textData}
                                    authenticated={props.authenticated}
                                    navigateToPageName={props.navigateToPageName}
                                    responsive={responsiveClass}
                                />
                            </div>
                            <Sidebar
                                getPageUrlByName={props.getPageUrlByName}
                                textData={!!textData && textData}
                                responsive={responsiveClass}
                            />
                            {props.currentPage === "account_orders" && (
                                <OrderOverview
                                    navigateToPageName={props.navigateToPageName}
                                    responsive={responsiveClass}
                                    orderData={orders}
                                    textData={!!textData && textData}
                                />
                            )}
                            {props.currentPage === "account_quotations" && (
                                <QuotationOverview
                                    responsive={responsiveClass}
                                    quotationData={savedQuotations}
                                    textData={!!textData && textData}
                                    loggedInUser={loggedInUser}
                                    navigateToPageName={props.navigateToPageName}
                                />
                            )}
                            {props.currentPage === "account_details" && (
                                <Details
                                    responsive={responsiveClass}
                                    navigateToPageName={props.navigateToPageName}
                                    textData={!!textData && textData}
                                />
                            )}
                            {props.currentPage === "product_library" && (
                                <ProductLibraryOverview
                                    navigateToPageName={props.navigateToPageName}
                                    responsive={responsiveClass}
                                    textData={!!textData && textData}
                                />
                            )}
                        </>
                    )}
                </div>
            )}
        </>
    );
}
