import TableHeader from "./TableHeader";
import TableRow from "./TableRow";
import TableFooter from "./TableFooter";
import { useEffect, useState } from "react";
import Label from "../Label";
export default function Table(props) {
    const [useData, setUseData] = useState();
    const [useTableData, setUseTableData] = useState();
    const [quotationTable, setQuotationTable] = useState();
    const [savedQuotationTable, setSavedQuotationTable] = useState();
    const [ordersTable, setOrdersTable] = useState();
    const [libraryTable, setLibraryTable] = useState();
    const [totalPriceIsLoading, setTotalPriceIsLoading] = useState(false);
    const [disableCheckoutButton, setDisableCheckoutButton] = useState(true);

    useEffect(() => {
        let found = false;
        for (let model in props.data[0].data) {
            // Check if numeric or not
            if (isNaN(parseFloat(props.data[0].data[model].price))) {
                found = true;
            }
        }

        if (found) {
            setDisableCheckoutButton(true);
        } else {
            setDisableCheckoutButton(false);
        }
    }, [props.data]);

    useEffect(() => {
        if (!!props.textData) {
            const quotationTable = {
                headerData: {
                    className: "head--quotation",
                    headers: [
                        props.textData.table["filename"],
                        props.textData.table["settings"],
                        props.textData.table["delivery_time"],
                        props.textData.table["quantity"],
                        props.textData.table["unit_price"],
                        props.textData.table["price"],
                    ],
                },
                rowData: {
                    data: props.data[0]?.data,
                    onModelChange: props.onModelChange,
                    interactionElements: {
                        copyBtn: {
                            iconClass: "__copy-icon",
                            name: "copy",
                        },
                        deleteBtn: {
                            iconClass: "__delete-icon",
                            name: "delete",
                        },
                        quantityInput: {
                            name: "quantity",
                        },
                        checkbox: {
                            name: "checkbox",
                            checked: false,
                        },
                    },
                },
                // @TODO: Make seperate buttons in footerData to conclude which buttons to show
                footerData: {
                    className: "footer--quotation",
                    buttons: {
                        name: "quotation",
                        save: {
                            className: "button--footer",
                            icon: "button--save-quotation-icon",
                            text: props.textData["footer"]["request_quotation"],
                        },
                        checkout: {
                            className: "button--footer",
                            icon: "button--pay-quotation-icon",
                            editIcon: "button--edit-quotation-icon",
                            text: props.textData["footer"]["to_checkout"],
                            editText: props.textData["footer"]["save_quotation"],
                        },
                        saveQuotation: props.saveQuotation,
                    },
                    liveChat: {
                        text: props.textData["footer"]["can_i_help_you"],
                        icon: "button--live-chat-icon",
                    },
                    popupData: props.popupData,
                    loginPopupData: props.loginData,
                    data: props.data[0]?.data,
                },
            };
            setQuotationTable(quotationTable);

            const ordersTable = {
                headerData: {
                    className: "head--order-overview",
                    headers: [
                        props.textData.table["models_amount"],
                        props.textData.table["project_number"],
                        props.textData.table["order_date"],
                        props.textData.table["reference"],
                        props.textData.table["status"],
                        props.textData.table["price"],
                    ],
                    currentOrderTitle: props.textData["current_orders_title"],
                    historyOrderTitle: props.textData["history_orders_title"],
                },
                rowData: {
                    data: props.data[0]?.orders,
                    onModelChange: props.onModelChange,
                    interactionElements: {
                        downloadBtn: {
                            iconClass: "__download-icon",
                            name: "download",
                        },
                        viewBtn: {
                            iconClass: "__view-icon",
                            name: "view",
                        },
                        shippingInfoBtn: {
                            iconClass: "__shipping-info-icon",
                            name: "shipping-info",
                        },
                    },
                },
                // footerData: {
                //     className: "footer--order-overview",
                //     buttonQuotation: {
                //         name: "quotation",
                //         saveClassName: "button--quotation",
                //         saveText: "Offerte aanvragen",
                //         saveIcon: "button--quotation-icon",
                //         payClassName: "button--pay-quotation",
                //         payText: "Afrekenen",
                //         payIcon: "button--pay-quotation-icon"
                //     },
                //     liveChat: {
                //         text: "Kan ik je ergens mee helpen?",
                //         icon: "button--live-chat-icon"
                //     }
                // },
            };
            setOrdersTable(ordersTable);

            const libraryTable = {
                headerData: {
                    className: "head--library-overview",
                    headers: [
                        props.textData.table["filename"],
                        props.textData.table["settings"],
                        props.textData.table["quantity"],
                        props.textData.table["price"],
                    ],
                    tableTitle: props.textData.table.title,
                },
                rowData: {
                    data: convertLibraryDataToRowData(props.data[0]),
                    // onProductChange: props.onProductChange,
                    interactionElements: {
                        downloadBtn: {
                            iconClass: "__download-icon",
                            name: "download",
                        },
                        cartBtn: {
                            iconClass: "__cart-icon",
                            name: "cart",
                        },
                        quantityInput: {
                            name: "quantity",
                        },
                    },
                },
                // footerData: {
                //     className: "footer--order-overview",
                //     liveChat: {
                //         text: "Kan ik je ergens mee helpen?",
                //         icon: "button--live-chat-icon"
                //     }
                // },
            };
            setLibraryTable(libraryTable);

            const savedQuotationsTable = {
                headerData: {
                    className: "head--order-overview",
                    headers: [
                        props.textData.table["models_amount"],
                        props.textData.table["project_number"],
                        props.textData.table["order_date"],
                        props.textData.table["reference"],
                        props.textData.table["status"],
                        props.textData.table["price"],
                    ],
                    tableTitle: props.textData.table.title,
                },
                rowData: {
                    data: props.data[0]?.orders,
                    onModelChange: props.onModelChange,
                    interactionElements: {
                        downloadBtn: {
                            iconClass: "__download-icon",
                            name: "download",
                        },
                        viewBtn: {
                            iconClass: "__view-icon",
                            name: "view",
                        },
                        deleteBtn: {
                            iconClass: "__delete-icon",
                            name: "delete",
                        },
                    },
                },
            };
            setSavedQuotationTable(savedQuotationsTable);
        }
    }, [props.textData, props.data]);

    useEffect(() => {
        if (!!props.data[0]?.section) {
            switch (props.data[0].section) {
                case "quotation":
                    setUseData(quotationTable);
                    setUseTableData("quotation");
                    break;
                case "orders":
                    setUseData(ordersTable);
                    setUseTableData("orders");
                    break;
                case "library":
                    setUseData(libraryTable);
                    setUseTableData("library");
                    break;
                case "savedQuotation":
                    setUseData(savedQuotationTable);
                    setUseTableData("savedQuotation");
                    break;
                default:
                    break;
            }
        }
    }, [quotationTable, ordersTable, libraryTable, props.data]);

    const convertLibraryDataToRowData = (data) => {
        if (!!props.data[0]?.section === null || props.data[0]?.section !== "library") {
            return data;
        }

        let libraries = data;
        let products = [];
        libraries.forEach((library) => {
            library.products.forEach((product) => {
                // Check if product already exists in products based on product.id
                let found = false;
                products.forEach((productInArray) => {
                    if (productInArray.id === product.id) {
                        found = true;
                    }
                });
                if (!found) {
                    products.push(product);
                }
            });
        });
        return products;
    };

    return (
        <>
            {!props.responsive && (
                <div>
                    {!!useData && (
                        <>
                            <div className="table-container">
                                <table>
                                    <TableHeader
                                        data={useData["headerData"]}
                                        rowData={useData["rowData"]["data"]}
                                        onModelChange={useData["rowData"]["onModelChange"]}
                                        textData={props.textData}
                                    />
                                    <TableRow
                                        data={useData["rowData"]["data"]}
                                        productsData={props.productsData}
                                        setProductsData={props.setProductsData}
                                        rowCheck={useTableData}
                                        onModelChange={useData["rowData"]["onModelChange"]}
                                        interaction={useData["rowData"]["interactionElements"]}
                                        textDataLabel={props.textDataLabel}
                                        checkoutState={props.checkoutState}
                                        mandatoryProductOptions={props.mandatoryProductOptions}
                                        navigateToPageName={props.navigateToPageName}
                                        getPageUrlByName={props.getPageUrlByName}
                                        allTextData={props.allTextData}
                                        updateShippingCosts={props.updateShippingCosts}
                                        updatePrice={props.updatePrice}
                                        setTotalPriceLoading={setTotalPriceIsLoading}
                                        openPopup={props.openPopup}
                                    />
                                </table>
                            </div>
                            {!!useData["footerData"] && (
                                <TableFooter
                                    navigateToPageName={props.navigateToPageName}
                                    data={useData["footerData"]}
                                    productsData={props.productsData}
                                    saveQuotation={props.saveQuotation}
                                    saveQuotationAndRedirectCheckout={props.saveQuotationAndRedirectCheckout}
                                    onModelChange={props.onModelChange}
                                    totalPriceLoading={totalPriceIsLoading}
                                    disableCheckoutButton={disableCheckoutButton}
                                    textData={props.textData}
                                    setSaveFooterSettings={props.setSaveFooterSettings}
                                />
                            )}
                        </>
                    )}
                </div>
            )}
            {!!props.responsive && (
                <div className={props.responsive + "table-container"}>
                    {!!useData && (
                        <>
                            <table>
                                <TableHeader
                                    responsive={props.responsive}
                                    data={useData["headerData"]}
                                    customHeader={!!useTableData && useTableData !== "quotation" && useTableData}
                                    onModelChange={useData["rowData"]["onModelChange"]}
                                    textData={props.textData}
                                />
                                <TableRow
                                    responsive={props.responsive}
                                    data={useData["rowData"]["data"]}
                                    rowCheck={useTableData}
                                    onModelChange={useData["rowData"]["onModelChange"]}
                                    interaction={useData["rowData"]["interactionElements"]}
                                    textDataLabel={props.textDataLabel}
                                    mandatoryProductOptions={props.mandatoryProductOptions}
                                    navigateToPageName={props.navigateToPageName}
                                    getPageUrlByName={props.getPageUrlByName}
                                    allTextData={props.allTextData}
                                    updateShippingCosts={props.updateShippingCosts}
                                    updatePrice={props.updatePrice}
                                    checkoutState={props.checkoutState}
                                    setTotalPriceLoading={setTotalPriceIsLoading}
                                    openPopup={props.openPopup}
                                />
                            </table>
                            {!!useData["footerData"] && (
                                <TableFooter
                                    navigateToPageName={props.navigateToPageName}
                                    responsive={props.responsive}
                                    data={useData["footerData"]}
                                    saveQuotationAndRedirectCheckout={props.saveQuotationAndRedirectCheckout}
                                    disableCheckoutButton={disableCheckoutButton}
                                    textData={props.textData}
                                    setSaveFooterSettings={props.setSaveFooterSettings}
                                />
                            )}
                        </>
                    )}
                </div>
            )}
        </>
    );
}
