import React, { Fragment, useEffect, useRef, useState } from "react";
import Label from "../Label";
import { Link, useNavigate } from "react-router-dom";
import RetrieveProductSettings from "../ProductSettings/RetrieveProductSettings";
import { useGlobalState } from "../GlobalContext";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faPercent } from "@fortawesome/pro-regular-svg-icons";
import { calcShippingCosts } from "./Shipping";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { getModelPrice, calculateVat } from "../Functions/price-calculations";

export default function Cart(props) {
    const navigate = useNavigate();
    const [mandatoryProductSettings, setMandatoryProductSettings] = useState([]);
    const [modelsData, setModelsData] = useState([]);
    const { productSettings, loggedInUser } = useGlobalState();
    const [subTotal, setSubTotal] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [discountOpen, setDiscountOpen] = useState(false);
    const [discounts, setDiscounts] = useState([]);
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [totalDiscountCodeAmount, setTotalDiscountCodeAmount] = useState(0);
    const [companyDiscount, setCompanyDiscount] = useState(0);
    const [btw, setBtw] = useState(0);
    const isHandlingDiscountCode = useRef(false);

    useEffect(() => {
        RetrieveProductSettings({ setMandatoryProductSettings });
        setSubTotalPrice();
        if (
            typeof props.modelsData !== "undefined" &&
            props.modelsData !== null &&
            Object.keys(props.modelsData).length > 0
        ) {
            let formattedData = props.modelsData[0].data.map((element) => {
                element.price = element.pivot.price;
                element.amount = element.pivot.amount;
                element.discount_multiplier = element.pivot.discount_multiplier;
                return element;
            });

            setModelsData([
                { data: formattedData, products: props.modelsData[0].products, order: props.modelsData[0].order },
            ]);
        } else {
            setModelsData(JSON.parse(localStorage.getItem("modelsData")) || []);
        }

        if (typeof props.shippingCountry !== "undefined" && props.shippingCountry !== null) {
            calcShippingCosts(modelsData, productSettings, props.shippingCountry).then((response) => {
                if (typeof props.setShippingInfo !== "undefined") {
                    props.setShippingInfo(response);
                }
            });
        } else {
            if (
                document.getElementsByName("country") !== null &&
                document.getElementsByName("country").length > 0 &&
                document.getElementsByName("country")[0].value === ""
            ) {
                props.setShippingInfo(null);
            } else if (
                loggedInUser !== null &&
                typeof loggedInUser !== "undefined" &&
                loggedInUser.company !== null &&
                typeof loggedInUser.company !== "undefined"
            ) {
                calcShippingCosts(modelsData, productSettings, loggedInUser.company.country).then((response) => {
                    if (typeof props.setShippingInfo !== "undefined") {
                        props.setShippingInfo(response);
                    }
                });
            } else if (
                loggedInUser !== null &&
                typeof loggedInUser !== "undefined" &&
                loggedInUser.user !== null &&
                typeof loggedInUser.user !== "undefined"
            ) {
                calcShippingCosts(modelsData, productSettings, loggedInUser.user.country).then((response) => {
                    if (typeof props.setShippingInfo !== "undefined") {
                        props.setShippingInfo(response);
                    }
                });
            } else {
                props.setShippingInfo(null);
            }
        }
    }, []);

    useEffect(() => {
        setSubTotalPrice();
        props.setDiscounts(discounts);
        getTotalPrice();
    }, [discounts, props.showShippingCosts, props.deliveryPrice, totalDiscountCodeAmount, subTotal]);

    useEffect(() => {
        getTotalPrice();
        setSubTotalPrice();
    }, [mandatoryProductSettings, btw]);

    useEffect(() => {
        if (typeof props.shippingCountry !== "undefined" && props.shippingCountry !== null) {
            calcShippingCosts(modelsData, productSettings, props.shippingCountry).then((response) => {
                if (typeof props.setShippingInfo !== "undefined") {
                    props.setShippingInfo(response);
                }
            });
        } else {
            if (
                document.getElementsByName("country") !== null &&
                document.getElementsByName("country").length > 0 &&
                document.getElementsByName("country")[0].value === ""
            ) {
                props.setShippingInfo(null);
            } else if (
                loggedInUser !== null &&
                typeof loggedInUser !== "undefined" &&
                loggedInUser.company !== null &&
                typeof loggedInUser.company !== "undefined"
            ) {
                calcShippingCosts(modelsData, productSettings, loggedInUser.company.country).then((response) => {
                    if (typeof props.setShippingInfo !== "undefined") {
                        props.setShippingInfo(response);
                    }
                });
            } else if (
                loggedInUser !== null &&
                typeof loggedInUser !== "undefined" &&
                loggedInUser.user !== null &&
                typeof loggedInUser.user !== "undefined"
            ) {
                calcShippingCosts(modelsData, productSettings, loggedInUser.user.country).then((response) => {
                    if (typeof props.setShippingInfo !== "undefined") {
                        props.setShippingInfo(response);
                    }
                });
            } else {
                props.setShippingInfo(null);
            }
        }
        setSubTotalPrice();
    }, [modelsData, props.shippingCountry]);

    const setSubTotalPrice = () => {
        if (modelsData.length > 0 && modelsData[0].data.length > 0) {
            let total = 0;
            modelsData[0].data.map((model) => {
                let modelPrice = 0;
                if (
                    typeof modelsData[0].order !== "undefined" &&
                    modelsData[0].order !== null &&
                    modelsData[0].order.lock_order_prices === true
                ) {
                    modelPrice = parseFloat(model.pivot.price * model.pivot.amount);
                } else if (model.admin_edited) {
                    modelPrice =
                        model.pivot.discount_price !== null
                            ? parseFloat(model.pivot.discount_price)
                            : parseFloat(model.pivot.price * model.pivot.amount);
                } else if (
                    typeof model.pivot !== "undefined" &&
                    typeof model.pivot.discount_price !== "undefined" &&
                    model.pivot.discount_price !== null
                ) {
                    total += parseFloat(model.pivot.discount_price);
                } else if (
                    loggedInUser.user !== null &&
                    !/[a-z]/i.test(parseFloat(model.price)) &&
                    loggedInUser.company !== null &&
                    loggedInUser.company.allow_staffel_discount == true
                ) {
                    modelPrice = parseFloat(model.price) * model.amount * parseFloat(model.discount_multiplier);
                } else {
                    modelPrice = parseFloat(model.price) * model.amount;
                }

                total += modelPrice;
                return model;
            });

            // if (
            //     loggedInUser.company !== null &&
            //     loggedInUser.company.discount_percentage > 0 &&
            //     loggedInUser.company.fixed_discount === true
            // ) {
            //     total -= parseFloat(loggedInUser.company.discount_percentage);
            // }

            // if (discounts.length > 0) {
            //     if (discounts[0].fixed == true) {
            //         total -= parseFloat(discounts[0].discount);
            //     }
            // }

            // const { deliveryPrice } = props;

            // Mandatory costs
            if (getExtraCosts() !== false) {
                total += parseFloat(getExtraCosts());
            }

            // let totalWithoutShipping = total;

            // if (total < 0) {
            //     total = 0;
            // }

            // if (totalWithoutShipping < 0) {
            //     totalWithoutShipping = 0;
            // }

            // if (deliveryPrice && deliveryPrice > 0) {
            //     total += deliveryPrice;
            // }

            // product library products
            const products = JSON.parse(window.localStorage.getItem("productsData"));
            if (typeof products !== "undefined" && products !== null) {
                products.forEach((product) => {
                    total += parseFloat(product.price) * product.quantity;
                });
            }

            setSubTotal(total.toFixed(2));
        } else {
            let subtotal = 0;
            // product library products
            const products = JSON.parse(window.localStorage.getItem("productsData"));
            if (typeof products !== "undefined" && products !== null) {
                products.forEach((product) => {
                    subtotal += parseFloat(product.price) * product.quantity;
                });
                setSubTotal(subtotal.toFixed(2));
            }
        }
        getTotalPrice();
    };

    const applyDiscountCode = () => {
        // No discounts if order prices are locked
        if (
            typeof modelsData[0].order !== "undefined" &&
            modelsData[0].order !== null &&
            modelsData[0].order.lock_order_prices === true
        ) {
            toast.error(props.textData["checkout/form"]["no_discount_found"], {
                position: toast.POSITION.TOP_CENTER,
            });
            return;
        }

        if (isHandlingDiscountCode.current) {
            return;
        }
        isHandlingDiscountCode.current = true;

        if (discounts.length > 0) {
            toast.error(props.textData["checkout/form"]["one_discount_allowed"], {
                position: toast.POSITION.TOP_CENTER,
            });
            return;
        }

        //discount-input
        let code = document.getElementById("discount-input").value;
        if (code.length > 0) {
            let found = false;
            discounts.forEach((discount) => {
                if (discount.code === code) {
                    toast.error(props.textData["checkout/form"]["discount_already_applied"], {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    found = true;
                }
            });
            if (found) {
                return;
            }

            axios
                .get(window.location.origin + "/api/discount?discount_code=" + code)
                .then((response) => {
                    setDiscounts((prev) => {
                        return [...prev, response.data];
                    });
                    toast.success(props.textData["checkout/form"]["discount_applied"], {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    isHandlingDiscountCode.current = false;
                })
                .catch((error) => {
                    toast.error(props.textData["checkout/form"]["no_discount_found"], {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    isHandlingDiscountCode.current = false;
                });
        }
    };

    const allModels = () => {
        const handleModelClick = (model) => {
            if (typeof props.modelsData !== "undefined" && props.modelsData !== null) {
                //navigate("/", { state: { model_id: model.id } });
                const urlParams = new URLSearchParams(window.location.search);
                const id = urlParams.get("id");

                if (id !== null && typeof id !== "undefined") {
                    props.navigateToPageName("account_edit_quotation", "?id=" + id);
                } else {
                    navigate("/", { state: { model_id: model.id } });
                }
            } else {
                navigate("/", { state: { model_id: model.id } });
            }
        };

        let productsOutput = null;
        const products = JSON.parse(window.localStorage.getItem("productsData"));
        if (typeof products !== "undefined" && products !== null) {
            productsOutput = products.map((product, index) => {
                return (
                    <div onClick={() => handleModelClick(product)} className="cart-item" key={index}>
                        <div className="model-image">
                            <img
                                src={typeof product.image === "undefined" ? product.preview_image : product.image}
                                alt={product.title}
                            />
                        </div>
                        <div className="model-info">
                            <p>
                                {product.file} <span className="amount">x {product.quantity}</span>
                            </p>
                            <div className="model-settings">
                                <Label
                                    model={product.file}
                                    data={product.settings}
                                    showall={true}
                                    textDataLabel={props.textData["sections/label"]}
                                    mandatoryProductOptions={mandatoryProductSettings}
                                />
                            </div>
                        </div>
                        <div className="model-price">
                            <p className="price">&euro;{(product.price * product.quantity).toFixed(2)}</p>
                        </div>
                    </div>
                );
            });
        }

        if (modelsData.length > 0 && modelsData[0].data.length > 0) {
            let models = modelsData[0].data.map((model, index) => {
                return (
                    <div onClick={() => handleModelClick(model)} className="cart-item" key={index}>
                        <div className="model-image">
                            <img
                                src={typeof model.image === "undefined" ? model.preview_image : model.image}
                                alt={model.title}
                            />
                        </div>
                        <div className="model-info">
                            <p>
                                {model.file} <span className="amount">x {model.amount}</span>
                            </p>
                            <div className="model-settings">
                                <Label
                                    model={model.file}
                                    data={model.settings}
                                    showall={true}
                                    textDataLabel={props.textData["sections/label"]}
                                    mandatoryProductOptions={mandatoryProductSettings}
                                />
                            </div>
                        </div>
                        <div className="model-price">
                            <p>{getModelPrice(model, loggedInUser, modelsData[0].order)}</p>
                        </div>
                    </div>
                );
            });

            if (productsOutput !== null) {
                models = [...models, ...productsOutput];
            }

            return models;
        } else if (typeof productsOutput !== "undefined" && productsOutput !== null) {
            return productsOutput;
        } else {
            return <p>Geen items in winkelmand</p>;
        }
    };

    const toggleDiscount = () => {
        const discountCode = document.querySelector(".discount-code");
        discountCode.classList.toggle("discount-open");
        setDiscountOpen(!discountOpen);
    };

    const getTotalPrice = () => {
        if (modelsData.length > 0 && modelsData[0].data.length > 0) {
            let total = 0;
            let totalDiscount = 0;
            let companyDiscount = 0;
            let totalDiscountCodeAmount = 0;
            let totalExclDiscount = 0;
            let totalOnlyStaffel = 0;
            modelsData[0].data.map((model) => {
                const originalModelPrice = parseFloat(model.price) * model.amount;
                let modelPrice = 0;
                if (
                    typeof modelsData[0].order !== "undefined" &&
                    modelsData[0].order !== null &&
                    modelsData[0].order.lock_order_prices === true
                ) {
                    modelPrice = parseFloat(model.pivot.price * model.pivot.amount);
                    totalOnlyStaffel += modelPrice;
                } else if (model.admin_edited) {
                    modelPrice =
                        model.pivot.discount_price !== null
                            ? parseFloat(model.pivot.discount_price)
                            : parseFloat(model.pivot.price * model.pivot.amount);
                    totalOnlyStaffel += modelPrice;
                } else if (
                    typeof model.pivot !== "undefined" &&
                    typeof model.pivot.discount_price !== "undefined" &&
                    model.pivot.discount_price !== null
                ) {
                    total += parseFloat(model.pivot.discount_price);
                    totalOnlyStaffel += parseFloat(model.pivot.discount_price);
                } else if (
                    loggedInUser.user !== null &&
                    !/[a-z]/i.test(parseFloat(model.price)) &&
                    loggedInUser.company !== null &&
                    loggedInUser.company.allow_staffel_discount == true
                ) {
                    let discount = parseFloat(model.price) * model.amount * parseFloat(model.discount_multiplier);
                    modelPrice = parseFloat(model.price) * model.amount * parseFloat(model.discount_multiplier);
                    totalOnlyStaffel += parseFloat(model.price) * model.amount * parseFloat(model.discount_multiplier);
                    totalDiscount += parseFloat(originalModelPrice - discount);
                } else {
                    modelPrice = parseFloat(model.price) * model.amount;
                    totalOnlyStaffel += modelPrice;
                }

                totalExclDiscount += originalModelPrice;
                total += modelPrice;
                return model;
            });

            // Library products
            if (
                typeof props.modelsData !== "undefined" &&
                typeof props.modelsData[0] !== "undefined" &&
                typeof props.modelsData[0].products !== "undefined" &&
                props.modelsData[0].products !== null
            ) {
                props.modelsData[0].products.map((libraryProduct) => {
                    const originalModelPrice = parseFloat(libraryProduct.price) * libraryProduct.quantity;
                    totalOnlyStaffel += originalModelPrice;
                    totalExclDiscount += originalModelPrice;
                    total += originalModelPrice;
                    return libraryProduct;
                });
            } else {
                const products = JSON.parse(window.localStorage.getItem("productsData"));
                if (typeof products !== "undefined" && products !== null) {
                    let productsTotal = 0;
                    products.forEach((product) => {
                        productsTotal += parseFloat(product.price) * product.quantity;
                    });
                    totalOnlyStaffel += productsTotal;
                    totalExclDiscount += productsTotal;
                    total += productsTotal;
                }
            }

            if (
                typeof modelsData[0].order === "undefined" ||
                modelsData[0].order === null ||
                (typeof modelsData[0].order !== "undefined" &&
                    modelsData[0].order !== null &&
                    modelsData[0].order.lock_order_prices === false)
            ) {
                if (discounts.length > 0) {
                    if (discounts[0].fixed == true) {
                        totalDiscountCodeAmount += parseFloat(discounts[0].discount);
                        totalDiscount += parseFloat(discounts[0].discount);
                        total = total - parseFloat(discounts[0].discount);
                    } else if (discounts[0].fixed == false) {
                        totalDiscountCodeAmount +=
                            parseFloat(totalOnlyStaffel + getExtraCosts()) * (parseFloat(discounts[0].discount) / 100);
                        totalDiscount +=
                            parseFloat(totalOnlyStaffel + getExtraCosts()) * (parseFloat(discounts[0].discount) / 100);
                        total =
                            total -
                            parseFloat(totalOnlyStaffel + getExtraCosts()) * (parseFloat(discounts[0].discount) / 100);
                    }
                }
                setTotalDiscountCodeAmount(parseFloat(totalDiscountCodeAmount));
                if (
                    loggedInUser.company !== null &&
                    loggedInUser.company.discount_percentage > 0 &&
                    loggedInUser.company.fixed_discount === true
                ) {
                    companyDiscount += parseFloat(loggedInUser.company.discount_percentage);
                    total -= parseFloat(loggedInUser.company.discount_percentage);
                } else if (
                    loggedInUser.company !== null &&
                    loggedInUser.company.discount_percentage > 0 &&
                    loggedInUser.company.fixed_discount === false
                ) {
                    let discount = parseFloat(total) * (parseFloat(loggedInUser.company.discount_percentage) / 100);
                    companyDiscount += discount;
                    total -= discount;
                }
            }

            if (getExtraCosts() !== false) {
                total += parseFloat(getExtraCosts());
                if (
                    loggedInUser.company !== null &&
                    loggedInUser.company.discount_percentage > 0 &&
                    loggedInUser.company.fixed_discount === false
                ) {
                    if (
                        (typeof modelsData[0].order === "undefined" && modelsData[0].order === null) ||
                        (typeof modelsData[0].order !== "undefined" &&
                            modelsData[0].order !== null &&
                            modelsData[0].order.lock_order_prices === false)
                    ) {
                        companyDiscount +=
                            parseFloat(getExtraCosts()) * (parseFloat(loggedInUser.company.discount_percentage) / 100);
                        total -=
                            parseFloat(getExtraCosts()) * (parseFloat(loggedInUser.company.discount_percentage) / 100);
                    }
                }
            }

            setCompanyDiscount(parseFloat(companyDiscount));
            setTotalDiscount(parseFloat(totalDiscount).toFixed(2));

            if (typeof getBtw() !== "undefined" && getBtw() !== null && getBtw() > 0) {
                if (
                    typeof props.shippingCountry !== "undefined" &&
                    props.shippingCountry !== null &&
                    isEU(props.shippingCountry)
                ) {
                    total += parseFloat(getBtw());
                }
            }

            if (
                typeof props.deliveryPrice !== "undefined" &&
                props.deliveryPrice !== null &&
                props.showShippingCosts === true
            ) {
                total += parseFloat(props.deliveryPrice);
            }
            if (total < 0) {
                total = 0;
                if (
                    typeof props.deliveryPrice !== "undefined" &&
                    props.deliveryPrice !== null &&
                    props.showShippingCosts === true
                ) {
                    total += parseFloat(props.deliveryPrice);
                    total += parseFloat(getBtw());
                }
            } else {
                total += parseFloat(getBtw());
            }

            setTotalPrice(total.toFixed(2));
        } else {
            let total = 0;
            // product library products
            const products = JSON.parse(window.localStorage.getItem("productsData"));
            if (typeof products !== "undefined" && products !== null) {
                let productsTotal = 0;
                products.forEach((product) => {
                    productsTotal += parseFloat(product.price) * product.quantity;
                });
                total += productsTotal;

                if (
                    typeof props.deliveryPrice !== "undefined" &&
                    props.deliveryPrice !== null &&
                    props.showShippingCosts === true
                ) {
                    total += parseFloat(props.deliveryPrice);
                }

                total += total * 0.21;
                setTotalPrice(total.toFixed(2));
            }
        }
    };

    const getBtw = () => {
        return calculateVat(
            null,
            loggedInUser,
            null,
            null,
            props.shippingCountry,
            props.deliveryPrice,
            subTotal,
            companyDiscount,
            totalDiscountCodeAmount
        );
    };

    const getExtraCosts = () => {
        let result = 0;
        if (
            typeof modelsData === "undefined" ||
            modelsData === null ||
            modelsData.length === 0 ||
            Object.keys(modelsData[0]).length === 0
        ) {
            return 0;
        }

        let checked = [];
        let checkedMedicals = [];
        modelsData[0].data.forEach((model) => {
            mandatoryProductSettings.forEach((setting) => {
                if (model.settings.application === setting.application) {
                    if (
                        (!checked.includes(setting.application) ||
                            (!checkedMedicals.includes(model.settings.case) &&
                                typeof model.settings.case !== "undefined" &&
                                model.settings.case !== null)) &&
                        setting.application === "Medical" &&
                        (model.settings.sterile === "true" || model.settings.sterile === true)
                    ) {
                        result += setting.mandatory_options[0].price;
                        checked.push(setting.application);
                        if (model.settings.case !== null && typeof model.settings.case !== "undefined") {
                            checkedMedicals.push(model.settings.case);
                        }
                    } else if (setting.application !== "Medical" && !checked.includes(setting.application)) {
                        result += setting.mandatory_options[0].price;
                        checked.push(setting.application);
                    }
                }
            });
        });
        return result;
    };

    const getExtraOrderCosts = () => {
        let counter = 0;
        let checked = [];
        let mandatoryCosts = [];
        let checkedMedicals = [];
        modelsData[0].data.forEach((model) => {
            mandatoryProductSettings.forEach((setting) => {
                if (model.settings.application === setting.application) {
                    if (
                        (!checked.includes(setting.application) &&
                            setting.application === "Medical" &&
                            (model.settings.sterile === "true" || model.settings.sterile === true)) ||
                        (!checkedMedicals.includes(model.settings.case) &&
                            typeof model.settings.case !== "undefined" &&
                            model.settings.case !== null)
                    ) {
                        mandatoryCosts.push({
                            application: setting.application,
                            price: setting.mandatory_options[0].price,
                        });
                        checked.push(setting.application);
                        if (model.settings.case !== null && typeof model.settings.case !== "undefined") {
                            checkedMedicals.push(model.settings.case);
                        }
                    } else if (setting.application !== "Medical" && !checked.includes(setting.application)) {
                        checked.push(setting.application);
                        mandatoryCosts.push({
                            application: setting.application,
                            price: setting.mandatory_options[0].price,
                        });
                    }
                }
            });
        });

        return mandatoryCosts.map((element) => {
            counter++;
            return (
                <Fragment key={counter}>
                    <p>
                        {props.textData["popups/order_overview"]["one_time_fee"]} ({element.application})
                    </p>
                    <p>€ {element.price.toFixed(2)}</p>
                </Fragment>
            );
        });
    };

    const isEU = (country) => {
        let eu_countries = [
            "Austria",
            "Belgium",
            "Bulgaria",
            "Cyprus",
            "Czech Republic",
            "Germany",
            "Denmark",
            "Estonia",
            "Greece",
            "Spain",
            "Finland",
            "France",
            "Croatia",
            "Hungary",
            "Ireland",
            "Italy",
            "Lithuania",
            "Luxembourg",
            "Latvia",
            "Malta",
            "Netherlands",
            "Poland",
            "Portugal",
            "Romania",
            "Sweden",
            "Slovenia",
            "Slovakia",
            //"Liechtenstein",
        ];

        country = country.toLowerCase().replace(/\b[a-z]/g, function (letter) {
            return letter.toUpperCase();
        });
        country = country.trim();

        // check if country is in EU
        if (eu_countries.includes(country)) {
            return true;
        }
        return false;
    };

    return (
        <Fragment>
            <div className="cart-container">
                <div>
                    <Link to={"/"}>
                        <img src={"/Oceanz_logo_nieuw_FC.png"} className="logo" alt="logo" />
                    </Link>
                    {!!props.textData && (
                        <div
                            className={
                                modelsData[0] !== null &&
                                typeof modelsData[0] !== "undefined" &&
                                !!modelsData[0].data &&
                                modelsData[0].data.length > 2
                                    ? "cart scrollable-cart"
                                    : "cart"
                            }
                        >
                            {allModels()}
                        </div>
                    )}
                </div>
                <div className="cart-calculations">
                    <label htmlFor="reference">{props.textData["checkout/form"]["reference"]}</label>
                    <input
                        type="text"
                        name="reference"
                        id="reference"
                        placeholder={props.textData["checkout/form"]["reference_placeholder"]}
                    />
                    <span className="divider"></span>
                    <span className="divider"></span>
                </div>
                <div className="calculations-container">
                    {parseFloat(getExtraCosts()) > 0 && <>{getExtraOrderCosts()}</>}
                    <p>{props.textData["checkout/form"]["subtotal"]}</p>
                    <p>€ {subTotal}</p>
                    {discounts.length > 0 && (
                        <>
                            {discounts.map((discount, index) => {
                                return (
                                    <Fragment key={index}>
                                        <p className="discount">
                                            {" "}
                                            {props.textData["checkout/form"]["discount_code"]}{" "}
                                            <span className="discount-code">({discount.code})</span>
                                        </p>
                                        <p className="discount">{"€ -" + totalDiscountCodeAmount.toFixed(2)}</p>
                                    </Fragment>
                                );
                            })}
                        </>
                    )}
                    {loggedInUser.company !== null &&
                        loggedInUser.company !== null &&
                        loggedInUser.company.discount_percentage > 0 &&
                        companyDiscount > 0 &&
                        loggedInUser.company.fixed_discount === false && (
                            <>
                                <p className="discount">
                                    {" "}
                                    {props.textData["checkout/form"]["company_discount"]}{" "}
                                    {"(" + parseInt(loggedInUser.company.discount_percentage) + "%)"}
                                </p>
                                <p className="discount">{"€ -" + companyDiscount.toFixed(2)}</p>
                            </>
                        )}
                    {loggedInUser.user !== null &&
                        loggedInUser.company !== null &&
                        loggedInUser.company.discount_percentage > 0 &&
                        companyDiscount > 0 &&
                        loggedInUser.company.fixed_discount === true && (
                            <>
                                <p className="discount">
                                    {" "}
                                    {props.textData["checkout/form"]["company_discount"]}{" "}
                                    {"(€-" + parseInt(loggedInUser.company.discount_percentage) + ")"}
                                </p>
                                <p className="discount">{"€ -" + companyDiscount.toFixed(2)}</p>
                            </>
                        )}
                    {!!props.deliveryPrice && props.deliveryPrice && (
                        <>
                            <p>{props.textData["checkout/form"]["shipping_costs"]}</p>
                            <p>€ {props.deliveryPrice.toFixed(2)}</p>
                        </>
                    )}
                    {getBtw() > 0 && (
                        <>
                            <p>{props.textData["checkout/form"]["vat"]} (21%)</p>
                            <p>€ {getBtw().toFixed(2)}</p>
                        </>
                    )}
                    <span className="divider"></span>
                    <span className="divider"></span>
                    <p>
                        <b>{props.textData["checkout/form"]["totalprice"]}</b>
                        {getBtw() > 0 && <> incl. {props.textData["checkout/form"]["vat"]}</>}
                    </p>
                    <p>€{totalPrice}</p>
                </div>
            </div>
            <div className="discount-code">
                <button className="toggleDiscount" onClick={toggleDiscount}>
                    {discountOpen && <FontAwesomeIcon size="lg" icon={faChevronUp} />}
                    {!discountOpen && <FontAwesomeIcon size="lg" icon={faChevronDown} />}
                </button>
                <p>
                    <FontAwesomeIcon icon={faPercent} /> &nbsp;{props.textData["checkout/form"]["apply_discount"]}
                </p>
                {discountOpen && (
                    <>
                        <input
                            type="text"
                            name="discount"
                            id="discount-input"
                            placeholder={props.textData["checkout/form"]["fill_your_discount_here"]}
                        />
                        <button className="discount-button" onClick={applyDiscountCode}>
                            {props.textData["checkout/form"]["apply"]}
                        </button>
                    </>
                )}
            </div>
        </Fragment>
    );
}
